<div class="home-container">
  <app-header></app-header>
  <div class="home-wrapper">
    <!-- Banner -->
    <section class="banner-section banner-slider">
      <div class="container">
        <div class="home-banner">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <p class="explore-text">
                <span><i class="fa-solid fa-thumbs-up me-2"></i></span>Location de
                voiture 100% sécurisée
              </p>
              <h1>Rendez vos trajets plus simples avec <span>TalabCar </span></h1>
              <h4>
                Nous mettons la satisfaction de nos clients au premier plan!
              </h4>
              <div class="customer-list">
                <!--
                  <div class="users-wrap">
                    <ul class="users-list">
                      <li>
                        <img
                          src="assets/img/profiles/avatar-15.jpg"
                          class="img-fluid aos"
                          alt="bannerimage"
                        />
                      </li>
                      <li>
                        <img
                          src="assets/img/profiles/avatar-15.jpg"
                          class="img-fluid aos"
                          alt="bannerimage"
                        />
                      </li>
                    </ul>
                    <div class="customer-info">
                      <h4>100+ Clients</h4>
                      <p>ont utilisé nos services de location</p>
                    </div>
                  </div>
                -->
                <div class="view-all">
                  <a
                    [routerLink]="routes.listingGrid"
                    class="btn btn-view d-inline-flex align-items-center"
                    >Louez une voiture</a
                  >
                  <a
                    [routerLink]="routes.listingGrid"
                    class="btn btn-secondary d-inline-flex align-items-center"
                    ><i class="bx bxs-plus-circle"></i>Proposez votre voiture
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="banner-image">
        <div class="banner-bg-img" data-aos="fade-left">
          <!--<img src="assets/img/car-shade.svg" class="img-fluid shade" alt="img" />-->
          <img src="assets/img/cars/car-card-03.png" class="img-fluid car" alt="img" />
          <!--
          <div class="banner-bg">
            <img src="assets/img/bg/ban-bg.png" class="img-fluid" alt="img" />
          </div>

          -->
        </div>
      </div>
      <!--
        <div class="banner-bgs">
          <img
            src="assets/img/bg/ban-bg-01.png"
            class="shape-01 img-fluid"
            alt="img"
          />
          <img
            src="assets/img/bg/ban-bg-02.png"
            class="shape-02 img-fluid"
            alt="img"
          />
          <img
            src="assets/img/bg/ban-bg-06.png"
            class="shape-06 img-fluid"
            alt="img"
          />
          <img
            src="assets/img/bg/ban-bg-07.png"
            class="shape-07 img-fluid"
            alt="img"
          />
          <img
            src="assets/img/bg/ban-bg-05.png"
            class="shape-08 img-fluid"
            alt="img"
          />
          <img
            src="assets/img/bg/ban-bg-06.png"
            class="shape-09 img-fluid"
            alt="img"
          />
        </div>
      -->
      <!-- Search
      TODO: 
      Improve structure (more classes, less styling)
      Move to component
      -->
      <div class="container">
        <div class="banner-form">
          <form>
            <div class="banner-search-list">
              <div class="input-block position-relative w-100">
                <button
                  class="d-flex align-items-center w-100 btn py-3 px-4"
                  type="button"
                  [ngClass]="{ 'input-button-focused': isLocationFieldFocused }"
                >
                  <!-- Icon on the left -->
                  <div class="d-flex align-items-center">
                    <i class="bx bx-map"></i>
                  </div>
                  <!-- Text and label on the right -->
                  <div class="input-labels flex-grow text-start ms-3">
                    <span class="d-block input-value" *ngIf="selectedLocation">{{
                      selectedLocation
                    }}</span>
                    <span *ngIf="!selectedLocation" class="d-block input-value"
                      >Ville ou Airport</span
                    >
                    <span class="d-block mt-1 text-sm input-label"
                      >Lieu de prise en charge</span
                    >
                  </div>
                  <!-- Clear button -->
                  <button
                    *ngIf="selectedLocation && isLocationFieldFocused"
                    type="button"
                    class="input-clear btn btn-light position-absolute end-0 top-50 translate-middle-y me-2"
                    (mousedown)="clearLocation($event)"
                  >
                    <i class="bx bx-x"></i>
                  </button>
                </button>
                <!-- Hidden Input with Typeahead -->
                <input
                  type="text"
                  class="position-absolute top-0 start-0 w-100 h-100 opacity-0"
                  [typeahead]="cities"
                  [(ngModel)]="selectedLocation"
                  (typeaheadOnSelect)="onLocationSelect($event)"
                  [typeaheadMinLength]="0"
                  [typeaheadWaitMs]="100"
                  [adaptivePosition]="true"
                  [typeaheadScrollable]="true"
                  [typeaheadOptionsInScrollableView]="5"
                  name="location"
                  (focus)="onLocationFieldFocus()"
                  (blur)="onLocationFieldBlur()"
                  #locationInput
                />
              </div>
              <!-- Divider between inputs -->
              <div class="banner-form-divider mx-3"></div>
              <div class="input-block position-relative w-100">
                <button
                  class="d-flex align-items-center w-100 btn py-3 px-4"
                  type="button"
                  [ngClass]="{ 'input-button-focused': isDateFieldFocused }"
                >
                  <!-- Icon on the left -->
                  <div class="d-flex align-items-center">
                    <i class="bx bx-calendar"></i>
                  </div>
                  <!-- Text and label on the right -->
                  <div class="flex-grow text-start ms-3">
                    <span class="d-block input-value" *ngIf="selectedDateRange">{{
                      selectedDateRange
                    }}</span>
                    <span class="d-block mt-1 text-sm input-label"
                      >Prise en charge - Restitution</span
                    >
                  </div>
                  <button
                    *ngIf="selectedDateRange && isDateFieldFocused"
                    type="button"
                    class="input-clear btn btn-light position-absolute end-0 top-50 translate-middle-y me-2"
                    (mousedown)="clearDateRange()"
                  >
                    <i class="bx bx-x"></i>
                  </button>
                </button>
                <!-- Hidden Date Range Picker -->
                <input
                  type="text"
                  class="position-absolute top-0 start-0 w-100 h-100 opacity-0"
                  bsDaterangepicker
                  #dateRangePickerInput="bsDaterangepicker"
                  [bsConfig]="{
                    adaptivePosition: true,
                    rangeInputFormat: 'MMM D'
                  }"
                  (bsValueChange)="onDateSelected($event)"
                  (focus)="onDateFieldFocus()"
                  (blur)="onDateFieldBlur()"
                />
                <div class="input-block-btn">
                  <button class="btn btn-primary d-flex" type="submit">
                    <i class="bx bx-search-alt"></i>
                  </button>
                </div>
      
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- /Search -->
    </section>
    <!-- /Banner -->

    <!-- How it works -->
    <section class="section how-it-works">
      <!--
        <div class="service-right">
          <img
            src="assets/img/bg/service-right.svg"
            class="img-fluid"
            alt="services right"
          />
        </div>
      -->
      <div class="container">
        <!-- Heading title-->
        <div class="section-heading">
          <h2>Comment ça marche</h2>
        </div>
        <!-- /Heading title -->
        <div class="services-work">
          <div class="row">
            <div class="col-lg-4 col-md-4 col-12">
              <div class="services-group services-line">
                <div class="services-icon border-secondary">
                  <img
                    class="icon-img bg-dark"
                    src="assets/img/icons/services-icon-01.svg"
                    alt="Choose Locations"
                  />
                </div>
                <div class="services-content">
                  <h3>1. Choisissez une destination</h3>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s,
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
              <div class="services-group services-line">
                <div class="services-icon border-secondary">
                  <img
                    class="icon-img bg-primary"
                    src="assets/img/icons/services-icon-02.svg"
                    alt="Choose Locations"
                  />
                </div>
                <div class="services-content">
                  <h3>2. Sélectionnez date de Retrait</h3>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s,
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
              <div class="services-group">
                <div class="services-icon border-dark">
                  <img
                    class="icon-img bg-dark"
                    src="assets/img/icons/services-icon-03.svg"
                    alt="Choose Locations"
                  />
                </div>
                <div class="services-content">
                  <h3>3. Réservez votre voiture</h3>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s,
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- /How it works -->

    <!-- Popular Cars -->
    <section class="section popular-cars">
      <div class="container">
        <div class="popular-cars-inner">
          <div class="section-heading">
            <h2>Découvrez les voitures les plus populaires</h2>
            <p>
              Les marques les plus populaires en raison de leur fiabilité, leur
              accessibilité et leurs caractéristiques.
            </p>
          </div>
          <!-- /Heading title -->
          <div class="row justify-content-center">
            <div class="col-lg-12">
              <div class="listing-tabs-group">
                <ul class="nav listing-buttons gap-3" data-bs-tabs="tabs">
                  <li>
                    <a data-bs-toggle="tab" href="#Carhonda" class="active"
                    >
                      <span>
                        <img src="assets/img/icons/car-icon-03.svg" alt="Honda" />
                      </span>
                      Hyundai
                    </a>
                  </li>
                  <li>
                    <a
                      aria-current="true"
                      data-bs-toggle="tab"
                      href="#Carmazda"
                    >
                      <span>
                        <img src="assets/img/icons/car-icon-01.svg" alt="Mazda" />
                      </span>
                      Mazda
                    </a>
                  </li>
                  <li>
                    <a data-bs-toggle="tab" href="#Caraudi">
                      <span>
                        <img src="assets/img/icons/car-icon-02.svg" alt="Audi" />
                      </span>
                      Audi
                    </a>
                  </li>
                  <li>
                    <a data-bs-toggle="tab" href="#Cartoyota">
                      <span>
                        <img src="assets/img/icons/car-icon-04.svg" alt="Toyota" />
                      </span>
                      Toyota
                    </a>
                  </li>
                  <li>
                    <a data-bs-toggle="tab" href="#Cartesla">
                      <span>
                        <img src="assets/img/icons/car-icon-06.svg" alt="Tesla" />
                      </span>
                      Tesla
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="tab-content">
            <div class="tab-pane active" id="Carhonda">
              <div class="row">
                <!-- col -->
                <div class="col-lg-4 col-md-6 col-12">
                  <div class="top-rated-card">
                    <div class="rated-car-img slide-card-images">
                      <div class="image-slider">
                        <owl-carousel-o
                          [options]="carOwlOptions"
                          class="blog-slider"
                        >
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-02.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-01.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-03.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                        </owl-carousel-o>
                      </div>
                    </div>
                    <div class="rated-car-content">
                      <div class="car-content-head">
                        <div class="head-items-left">
                          <h4>
                            <a [routerLink]="routes.listingDetails">Hyundai Kona</a>
                          </h4>
                          <span class="d-flex align-items-center"
                            ><i class="bx bx-map me-2"></i>Casablanca</span
                          >
                        </div>
                        <div class="head-items-right">
                          <div class="rated-star">
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star"></i>
                          </div>
                          <span class="km-badge"
                            ><i class="bx bx-map-pin me-1"></i>2.2km</span
                          >
                        </div>
                      </div>
                      <div class="car-content-body">
                        <ul class="car-features-info">
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/yacht-feature-icon-03.svg"
                                alt="Img"
                            /></span>
                            <h6><span>Diesel</span></h6>
                          </li>
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/yacht-feature-icon-05.svg"
                                alt="Img"
                            /></span>
                            <h6><span>Manuelle</span></h6>
                          </li>
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/car-parts-02.svg"
                                alt="Img"
                            /></span>
                            <h6><span>10KM</span></h6>
                          </li>
                        </ul>
                      </div>
                      <div class="car-content-footer">
                        <p><span>200 DH </span>/Jour</p>
                        <div class="car-book-btn">
                          <a [routerLink]="routes.listingDetails" class="btn btn-secondary"
                            >Réserver</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6 col-12">
                  <div class="top-rated-card">
                    <div class="rated-car-img slide-card-images">
                      <div class="image-slider">
                        <owl-carousel-o
                          [options]="carOwlOptions"
                          class="blog-slider"
                        >
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-01.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-02.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-03.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                        </owl-carousel-o>
                      </div>
                    </div>
                    <div class="rated-car-content">
                      <div class="car-content-head">
                        <div class="head-items-left">
                          <h4>
                            <a [routerLink]="routes.listingDetails">Hyundai Kona</a>
                          </h4>
                          <span class="d-flex align-items-center"
                            ><i class="bx bx-map me-2"></i>Casablanca</span
                          >
                        </div>
                        <div class="head-items-right">
                          <div class="rated-star">
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star"></i>
                          </div>
                          <span class="km-badge"
                            ><i class="bx bx-map-pin me-1"></i>2.2km</span
                          >
                        </div>
                      </div>
                      <div class="car-content-body">
                        <ul class="car-features-info">
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/yacht-feature-icon-03.svg"
                                alt="Img"
                            /></span>
                            <h6><span>Diesel</span></h6>
                          </li>
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/yacht-feature-icon-05.svg"
                                alt="Img"
                            /></span>
                            <h6><span>Manuelle</span></h6>
                          </li>
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/car-parts-02.svg"
                                alt="Img"
                            /></span>
                            <h6><span>10KM</span></h6>
                          </li>
                        </ul>
                      </div>
                      <div class="car-content-footer">
                        <p><span>200 DH </span>/Jour</p>
                        <div class="car-book-btn">
                          <a [routerLink]="routes.listingDetails" class="btn btn-secondary"
                            >Réserver</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6 col-12">
                  <div class="top-rated-card">
                    <div class="rated-car-img slide-card-images">
                      <div class="image-slider">
                        <owl-carousel-o
                          [options]="carOwlOptions"
                          class="blog-slider"
                        >
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-03.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-02.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-01.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                        </owl-carousel-o>
                      </div>
                    </div>
                    <div class="rated-car-content">
                      <div class="car-content-head">
                        <div class="head-items-left">
                          <h4>
                            <a [routerLink]="routes.listingDetails">Hyundai Kona</a>
                          </h4>
                          <span class="d-flex align-items-center"
                            ><i class="bx bx-map me-2"></i>Casablanca</span
                          >
                        </div>
                        <div class="head-items-right">
                          <div class="rated-star">
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star"></i>
                          </div>
                          <span class="km-badge"
                            ><i class="bx bx-map-pin me-1"></i>2.2km</span
                          >
                        </div>
                      </div>
                      <div class="car-content-body">
                        <ul class="car-features-info">
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/yacht-feature-icon-03.svg"
                                alt="Img"
                            /></span>
                            <h6><span>Diesel</span></h6>
                          </li>
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/yacht-feature-icon-05.svg"
                                alt="Img"
                            /></span>
                            <h6><span>Manuelle</span></h6>
                          </li>
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/car-parts-02.svg"
                                alt="Img"
                            /></span>
                            <h6><span>10KM</span></h6>
                          </li>
                        </ul>
                      </div>
                      <div class="car-content-footer">
                        <p><span>200 DH </span>/Jour</p>
                        <div class="car-book-btn">
                          <a [routerLink]="routes.listingDetails" class="btn btn-secondary"
                            >Réserver</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /col -->
              </div>
            </div>
            <div class="tab-pane fade" id="Carmazda">
              <div class="row">
                <!-- col -->
                <div class="col-lg-4 col-md-6 col-12">
                  <div class="top-rated-card">
                    <div class="rated-car-img slide-card-images">
                      <div class="image-slider">
                        <owl-carousel-o
                          [options]="carOwlOptions"
                          class="blog-slider"
                        >
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-02.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-01.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-03.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                        </owl-carousel-o>
                      </div>
                    </div>
                    <div class="rated-car-content">
                      <div class="car-content-head">
                        <div class="head-items-left">
                          <h4>
                            <a [routerLink]="routes.listingDetails">Hyundai Kona</a>
                          </h4>
                          <span class="d-flex align-items-center"
                            ><i class="bx bx-map me-2"></i>Casablanca</span
                          >
                        </div>
                        <div class="head-items-right">
                          <div class="rated-star">
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star"></i>
                          </div>
                          <span class="km-badge"
                            ><i class="bx bx-map-pin me-1"></i>2.2km</span
                          >
                        </div>
                      </div>
                      <div class="car-content-body">
                        <ul class="car-features-info">
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/yacht-feature-icon-03.svg"
                                alt="Img"
                            /></span>
                            <h6><span>Diesel</span></h6>
                          </li>
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/yacht-feature-icon-05.svg"
                                alt="Img"
                            /></span>
                            <h6><span>Manuelle</span></h6>
                          </li>
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/car-parts-02.svg"
                                alt="Img"
                            /></span>
                            <h6><span>10KM</span></h6>
                          </li>
                        </ul>
                      </div>
                      <div class="car-content-footer">
                        <p><span>200 DH </span>/Jour</p>
                        <div class="car-book-btn">
                          <a [routerLink]="routes.listingDetails" class="btn btn-secondary"
                            >Réserver</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6 col-12">
                  <div class="top-rated-card">
                    <div class="rated-car-img slide-card-images">
                      <div class="image-slider">
                        <owl-carousel-o
                          [options]="carOwlOptions"
                          class="blog-slider"
                        >
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-01.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-02.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-03.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                        </owl-carousel-o>
                      </div>
                    </div>
                    <div class="rated-car-content">
                      <div class="car-content-head">
                        <div class="head-items-left">
                          <h4>
                            <a [routerLink]="routes.listingDetails">Hyundai Kona</a>
                          </h4>
                          <span class="d-flex align-items-center"
                            ><i class="bx bx-map me-2"></i>Casablanca</span
                          >
                        </div>
                        <div class="head-items-right">
                          <div class="rated-star">
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star"></i>
                          </div>
                          <span class="km-badge"
                            ><i class="bx bx-map-pin me-1"></i>2.2km</span
                          >
                        </div>
                      </div>
                      <div class="car-content-body">
                        <ul class="car-features-info">
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/yacht-feature-icon-03.svg"
                                alt="Img"
                            /></span>
                            <h6><span>Diesel</span></h6>
                          </li>
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/yacht-feature-icon-05.svg"
                                alt="Img"
                            /></span>
                            <h6><span>Manuelle</span></h6>
                          </li>
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/car-parts-02.svg"
                                alt="Img"
                            /></span>
                            <h6><span>10KM</span></h6>
                          </li>
                        </ul>
                      </div>
                      <div class="car-content-footer">
                        <p><span>200 DH </span>/Jour</p>
                        <div class="car-book-btn">
                          <a [routerLink]="routes.listingDetails" class="btn btn-secondary"
                            >Réserver</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6 col-12">
                  <div class="top-rated-card">
                    <div class="rated-car-img slide-card-images">
                      <div class="image-slider">
                        <owl-carousel-o
                          [options]="carOwlOptions"
                          class="blog-slider"
                        >
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-03.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-02.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-01.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                        </owl-carousel-o>
                      </div>
                    </div>
                    <div class="rated-car-content">
                      <div class="car-content-head">
                        <div class="head-items-left">
                          <h4>
                            <a [routerLink]="routes.listingDetails">Hyundai Kona</a>
                          </h4>
                          <span class="d-flex align-items-center"
                            ><i class="bx bx-map me-2"></i>Casablanca</span
                          >
                        </div>
                        <div class="head-items-right">
                          <div class="rated-star">
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star"></i>
                          </div>
                          <span class="km-badge"
                            ><i class="bx bx-map-pin me-1"></i>2.2km</span
                          >
                        </div>
                      </div>
                      <div class="car-content-body">
                        <ul class="car-features-info">
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/yacht-feature-icon-03.svg"
                                alt="Img"
                            /></span>
                            <h6><span>Diesel</span></h6>
                          </li>
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/yacht-feature-icon-05.svg"
                                alt="Img"
                            /></span>
                            <h6><span>Manuelle</span></h6>
                          </li>
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/car-parts-02.svg"
                                alt="Img"
                            /></span>
                            <h6><span>10KM</span></h6>
                          </li>
                        </ul>
                      </div>
                      <div class="car-content-footer">
                        <p><span>200 DH </span>/Jour</p>
                        <div class="car-book-btn">
                          <a [routerLink]="routes.listingDetails" class="btn btn-secondary"
                            >Réserver</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /col -->
              </div>
            </div>
            <div class="tab-pane fade" id="Caraudi">
              <div class="row">
                <!-- col -->
                <div class="col-lg-4 col-md-6 col-12">
                  <div class="top-rated-card">
                    <div class="rated-car-img slide-card-images">
                      <div class="image-slider">
                        <owl-carousel-o
                          [options]="carOwlOptions"
                          class="blog-slider"
                        >
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-02.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-01.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-03.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                        </owl-carousel-o>
                      </div>
                    </div>
                    <div class="rated-car-content">
                      <div class="car-content-head">
                        <div class="head-items-left">
                          <h4>
                            <a [routerLink]="routes.listingDetails">Hyundai Kona</a>
                          </h4>
                          <span class="d-flex align-items-center"
                            ><i class="bx bx-map me-2"></i>Casablanca</span
                          >
                        </div>
                        <div class="head-items-right">
                          <div class="rated-star">
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star"></i>
                          </div>
                          <span class="km-badge"
                            ><i class="bx bx-map-pin me-1"></i>2.2km</span
                          >
                        </div>
                      </div>
                      <div class="car-content-body">
                        <ul class="car-features-info">
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/yacht-feature-icon-03.svg"
                                alt="Img"
                            /></span>
                            <h6><span>Diesel</span></h6>
                          </li>
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/yacht-feature-icon-05.svg"
                                alt="Img"
                            /></span>
                            <h6><span>Manuelle</span></h6>
                          </li>
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/car-parts-02.svg"
                                alt="Img"
                            /></span>
                            <h6><span>10KM</span></h6>
                          </li>
                        </ul>
                      </div>
                      <div class="car-content-footer">
                        <p><span>200 DH </span>/Jour</p>
                        <div class="car-book-btn">
                          <a [routerLink]="routes.listingDetails" class="btn btn-secondary"
                            >Réserver</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6 col-12">
                  <div class="top-rated-card">
                    <div class="rated-car-img slide-card-images">
                      <div class="image-slider">
                        <owl-carousel-o
                          [options]="carOwlOptions"
                          class="blog-slider"
                        >
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-01.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-02.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-03.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                        </owl-carousel-o>
                      </div>
                    </div>
                    <div class="rated-car-content">
                      <div class="car-content-head">
                        <div class="head-items-left">
                          <h4>
                            <a [routerLink]="routes.listingDetails">Hyundai Kona</a>
                          </h4>
                          <span class="d-flex align-items-center"
                            ><i class="bx bx-map me-2"></i>Casablanca</span
                          >
                        </div>
                        <div class="head-items-right">
                          <div class="rated-star">
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star"></i>
                          </div>
                          <span class="km-badge"
                            ><i class="bx bx-map-pin me-1"></i>2.2km</span
                          >
                        </div>
                      </div>
                      <div class="car-content-body">
                        <ul class="car-features-info">
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/yacht-feature-icon-03.svg"
                                alt="Img"
                            /></span>
                            <h6><span>Diesel</span></h6>
                          </li>
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/yacht-feature-icon-05.svg"
                                alt="Img"
                            /></span>
                            <h6><span>Manuelle</span></h6>
                          </li>
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/car-parts-02.svg"
                                alt="Img"
                            /></span>
                            <h6><span>10KM</span></h6>
                          </li>
                        </ul>
                      </div>
                      <div class="car-content-footer">
                        <p><span>200 DH </span>/Jour</p>
                        <div class="car-book-btn">
                          <a [routerLink]="routes.listingDetails" class="btn btn-secondary"
                            >Réserver</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6 col-12">
                  <div class="top-rated-card">
                    <div class="rated-car-img slide-card-images">
                      <div class="image-slider">
                        <owl-carousel-o
                          [options]="carOwlOptions"
                          class="blog-slider"
                        >
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-03.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-02.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-01.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                        </owl-carousel-o>
                      </div>
                    </div>
                    <div class="rated-car-content">
                      <div class="car-content-head">
                        <div class="head-items-left">
                          <h4>
                            <a [routerLink]="routes.listingDetails">Hyundai Kona</a>
                          </h4>
                          <span class="d-flex align-items-center"
                            ><i class="bx bx-map me-2"></i>Casablanca</span
                          >
                        </div>
                        <div class="head-items-right">
                          <div class="rated-star">
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star"></i>
                          </div>
                          <span class="km-badge"
                            ><i class="bx bx-map-pin me-1"></i>2.2km</span
                          >
                        </div>
                      </div>
                      <div class="car-content-body">
                        <ul class="car-features-info">
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/yacht-feature-icon-03.svg"
                                alt="Img"
                            /></span>
                            <h6><span>Diesel</span></h6>
                          </li>
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/yacht-feature-icon-05.svg"
                                alt="Img"
                            /></span>
                            <h6><span>Manuelle</span></h6>
                          </li>
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/car-parts-02.svg"
                                alt="Img"
                            /></span>
                            <h6><span>10KM</span></h6>
                          </li>
                        </ul>
                      </div>
                      <div class="car-content-footer">
                        <p><span>200 DH </span>/Jour</p>
                        <div class="car-book-btn">
                          <a [routerLink]="routes.listingDetails" class="btn btn-secondary"
                            >Réserver</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /col -->
              </div>
            </div>
            <div class="tab-pane fade" id="Cartoyota">
              <div class="row">
                <!-- col -->
                <div class="col-lg-4 col-md-6 col-12">
                  <div class="top-rated-card">
                    <div class="rated-car-img slide-card-images">
                      <div class="image-slider">
                        <owl-carousel-o
                          [options]="carOwlOptions"
                          class="blog-slider"
                        >
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-02.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-01.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-03.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                        </owl-carousel-o>
                      </div>
                    </div>
                    <div class="rated-car-content">
                      <div class="car-content-head">
                        <div class="head-items-left">
                          <h4>
                            <a [routerLink]="routes.listingDetails">Hyundai Kona</a>
                          </h4>
                          <span class="d-flex align-items-center"
                            ><i class="bx bx-map me-2"></i>Casablanca</span
                          >
                        </div>
                        <div class="head-items-right">
                          <div class="rated-star">
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star"></i>
                          </div>
                          <span class="km-badge"
                            ><i class="bx bx-map-pin me-1"></i>2.2km</span
                          >
                        </div>
                      </div>
                      <div class="car-content-body">
                        <ul class="car-features-info">
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/yacht-feature-icon-03.svg"
                                alt="Img"
                            /></span>
                            <h6><span>Diesel</span></h6>
                          </li>
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/yacht-feature-icon-05.svg"
                                alt="Img"
                            /></span>
                            <h6><span>Manuelle</span></h6>
                          </li>
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/car-parts-02.svg"
                                alt="Img"
                            /></span>
                            <h6><span>10KM</span></h6>
                          </li>
                        </ul>
                      </div>
                      <div class="car-content-footer">
                        <p><span>200 DH </span>/Jour</p>
                        <div class="car-book-btn">
                          <a [routerLink]="routes.listingDetails" class="btn btn-secondary"
                            >Réserver</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6 col-12">
                  <div class="top-rated-card">
                    <div class="rated-car-img slide-card-images">
                      <div class="image-slider">
                        <owl-carousel-o
                          [options]="carOwlOptions"
                          class="blog-slider"
                        >
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-01.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-02.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-03.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                        </owl-carousel-o>
                      </div>
                    </div>
                    <div class="rated-car-content">
                      <div class="car-content-head">
                        <div class="head-items-left">
                          <h4>
                            <a [routerLink]="routes.listingDetails">Hyundai Kona</a>
                          </h4>
                          <span class="d-flex align-items-center"
                            ><i class="bx bx-map me-2"></i>Casablanca</span
                          >
                        </div>
                        <div class="head-items-right">
                          <div class="rated-star">
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star"></i>
                          </div>
                          <span class="km-badge"
                            ><i class="bx bx-map-pin me-1"></i>2.2km</span
                          >
                        </div>
                      </div>
                      <div class="car-content-body">
                        <ul class="car-features-info">
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/yacht-feature-icon-03.svg"
                                alt="Img"
                            /></span>
                            <h6><span>Diesel</span></h6>
                          </li>
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/yacht-feature-icon-05.svg"
                                alt="Img"
                            /></span>
                            <h6><span>Manuelle</span></h6>
                          </li>
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/car-parts-02.svg"
                                alt="Img"
                            /></span>
                            <h6><span>10KM</span></h6>
                          </li>
                        </ul>
                      </div>
                      <div class="car-content-footer">
                        <p><span>200 DH </span>/Jour</p>
                        <div class="car-book-btn">
                          <a [routerLink]="routes.listingDetails" class="btn btn-secondary"
                            >Réserver</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6 col-12">
                  <div class="top-rated-card">
                    <div class="rated-car-img slide-card-images">
                      <div class="image-slider">
                        <owl-carousel-o
                          [options]="carOwlOptions"
                          class="blog-slider"
                        >
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-03.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-02.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-01.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                        </owl-carousel-o>
                      </div>
                    </div>
                    <div class="rated-car-content">
                      <div class="car-content-head">
                        <div class="head-items-left">
                          <h4>
                            <a [routerLink]="routes.listingDetails">Hyundai Kona</a>
                          </h4>
                          <span class="d-flex align-items-center"
                            ><i class="bx bx-map me-2"></i>Casablanca</span
                          >
                        </div>
                        <div class="head-items-right">
                          <div class="rated-star">
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star"></i>
                          </div>
                          <span class="km-badge"
                            ><i class="bx bx-map-pin me-1"></i>2.2km</span
                          >
                        </div>
                      </div>
                      <div class="car-content-body">
                        <ul class="car-features-info">
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/yacht-feature-icon-03.svg"
                                alt="Img"
                            /></span>
                            <h6><span>Diesel</span></h6>
                          </li>
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/yacht-feature-icon-05.svg"
                                alt="Img"
                            /></span>
                            <h6><span>Manuelle</span></h6>
                          </li>
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/car-parts-02.svg"
                                alt="Img"
                            /></span>
                            <h6><span>10KM</span></h6>
                          </li>
                        </ul>
                      </div>
                      <div class="car-content-footer">
                        <p><span>200 DH </span>/Jour</p>
                        <div class="car-book-btn">
                          <a [routerLink]="routes.listingDetails" class="btn btn-secondary"
                            >Réserver</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /col -->
              </div>
            </div>
            <div class="tab-pane fade" id="Cartesla">
              <div class="row">
                <!-- col -->
                <div class="col-lg-4 col-md-6 col-12">
                  <div class="top-rated-card">
                    <div class="rated-car-img slide-card-images">
                      <div class="image-slider">
                        <owl-carousel-o
                          [options]="carOwlOptions"
                          class="blog-slider"
                        >
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-02.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-01.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-03.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                        </owl-carousel-o>
                      </div>
                    </div>
                    <div class="rated-car-content">
                      <div class="car-content-head">
                        <div class="head-items-left">
                          <h4>
                            <a [routerLink]="routes.listingDetails">Hyundai Kona</a>
                          </h4>
                          <span class="d-flex align-items-center"
                            ><i class="bx bx-map me-2"></i>Casablanca</span
                          >
                        </div>
                        <div class="head-items-right">
                          <div class="rated-star">
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star"></i>
                          </div>
                          <span class="km-badge"
                            ><i class="bx bx-map-pin me-1"></i>2.2km</span
                          >
                        </div>
                      </div>
                      <div class="car-content-body">
                        <ul class="car-features-info">
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/yacht-feature-icon-03.svg"
                                alt="Img"
                            /></span>
                            <h6><span>Diesel</span></h6>
                          </li>
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/yacht-feature-icon-05.svg"
                                alt="Img"
                            /></span>
                            <h6><span>Manuelle</span></h6>
                          </li>
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/car-parts-02.svg"
                                alt="Img"
                            /></span>
                            <h6><span>10KM</span></h6>
                          </li>
                        </ul>
                      </div>
                      <div class="car-content-footer">
                        <p><span>200 DH </span>/Jour</p>
                        <div class="car-book-btn">
                          <a [routerLink]="routes.listingDetails" class="btn btn-secondary"
                            >Réserver</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6 col-12">
                  <div class="top-rated-card">
                    <div class="rated-car-img slide-card-images">
                      <div class="image-slider">
                        <owl-carousel-o
                          [options]="carOwlOptions"
                          class="blog-slider"
                        >
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-01.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-02.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-03.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                        </owl-carousel-o>
                      </div>
                    </div>
                    <div class="rated-car-content">
                      <div class="car-content-head">
                        <div class="head-items-left">
                          <h4>
                            <a [routerLink]="routes.listingDetails">Hyundai Kona</a>
                          </h4>
                          <span class="d-flex align-items-center"
                            ><i class="bx bx-map me-2"></i>Casablanca</span
                          >
                        </div>
                        <div class="head-items-right">
                          <div class="rated-star">
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star"></i>
                          </div>
                          <span class="km-badge"
                            ><i class="bx bx-map-pin me-1"></i>2.2km</span
                          >
                        </div>
                      </div>
                      <div class="car-content-body">
                        <ul class="car-features-info">
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/yacht-feature-icon-03.svg"
                                alt="Img"
                            /></span>
                            <h6><span>Diesel</span></h6>
                          </li>
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/yacht-feature-icon-05.svg"
                                alt="Img"
                            /></span>
                            <h6><span>Manuelle</span></h6>
                          </li>
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/car-parts-02.svg"
                                alt="Img"
                            /></span>
                            <h6><span>10KM</span></h6>
                          </li>
                        </ul>
                      </div>
                      <div class="car-content-footer">
                        <p><span>200 DH </span>/Jour</p>
                        <div class="car-book-btn">
                          <a [routerLink]="routes.listingDetails" class="btn btn-secondary"
                            >Réserver</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /col -->
                <!-- col -->
                <div class="col-lg-4 col-md-6 col-12">
                  <div class="top-rated-card">
                    <div class="rated-car-img slide-card-images">
                      <div class="image-slider">
                        <owl-carousel-o
                          [options]="carOwlOptions"
                          class="blog-slider"
                        >
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-03.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-02.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                          <ng-template carouselSlide>
                            <div class="slide-images">
                              <a [routerLink]="routes.listingDetails">
                                <img
                                  src="assets/img/cars/car-card-01.png"
                                  class="img-fluid"
                                  alt="img"
                                />
                              </a>
                            </div>
                          </ng-template>
                        </owl-carousel-o>
                      </div>
                    </div>
                    <div class="rated-car-content">
                      <div class="car-content-head">
                        <div class="head-items-left">
                          <h4>
                            <a [routerLink]="routes.listingDetails">Hyundai Kona</a>
                          </h4>
                          <span class="d-flex align-items-center"
                            ><i class="bx bx-map me-2"></i>Casablanca</span
                          >
                        </div>
                        <div class="head-items-right">
                          <div class="rated-star">
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star filled"></i>
                            <i class="bx bxs-star"></i>
                          </div>
                          <span class="km-badge"
                            ><i class="bx bx-map-pin me-1"></i>2.2km</span
                          >
                        </div>
                      </div>
                      <div class="car-content-body">
                        <ul class="car-features-info">
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/yacht-feature-icon-03.svg"
                                alt="Img"
                            /></span>
                            <h6><span>Diesel</span></h6>
                          </li>
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/yacht-feature-icon-05.svg"
                                alt="Img"
                            /></span>
                            <h6><span>Manuelle</span></h6>
                          </li>
                          <li>
                            <span class="car-feature-icon"
                              ><img
                                src="assets/img/icons/car-parts-02.svg"
                                alt="Img"
                            /></span>
                            <h6><span>10KM</span></h6>
                          </li>
                        </ul>
                      </div>
                      <div class="car-content-footer">
                        <p><span>200 DH </span>/Jour</p>
                        <div class="car-book-btn">
                          <a [routerLink]="routes.listingDetails" class="btn btn-secondary"
                            >Réserver</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /col -->
              </div>
            </div>
          </div>
        </div>
          <!-- Heading title-->
      </div>
    </section>
    <!-- /Popular Cars -->

    <!-- Popular Cartypes -->
  <section class="section popular-cartype">
    <div class="container">
      <!-- Heading title-->
      <div class="section-heading">
        <h2>Les types de voitures les plus appréciés</h2>
        <p>
          Découvrez les modèles les plus demandés pour chaque style de voyage et chaque envie d’évasion.
        </p>
      </div>
      <!-- /Heading title -->
      <div class="row">
        <div class="popular-cartype-group">
          <div class="popular-cartype-slider owl-theme">
            <!-- owl carousel item -->
            <owl-carousel-o [options]="carTypesOptions">
              @for (data of carTypes; track data) {
              <ng-template carouselSlide>
                <div class="listing-owl-item">
                  <div class="listing-owl-group">
                    <div class="listing-owl-img">
                      <img
                        [src]="data.img"
                        class="img-fluid"
                        alt="Popular Cartypes"
                      />
                    </div>
                    <h6>{{ data.heading }}</h6>
                    <p>{{ data.cars }}</p>
                  </div>
                </div>
              </ng-template>
              }
            </owl-carousel-o>
            <!-- /owl carousel item -->
          </div>
        </div>
      </div>
      <!-- View More -->
      <div class="view-all text-center">
        <a
          [routerLink]="routes.listingGrid"
          class="btn btn-view d-inline-flex align-items-center"
          >Explorer toutes les options<span><i class="feather icon-arrow-right ms-2"></i></span
        ></a>
      </div>
      <!-- View More -->
    </div>
  </section>
  <!-- /Popular Cartypes -->

  <!-- Top Features Cars -->
  <section class="top-features-cars">
    <div class="container">
      <div class="top-features-cars-inner">
          <div class="top-rated-cars">
            <div class="row align-items-center">
              <div class="col-lg-4">
                <div class="section-header-two">
                  <h2>Nos Meilleurs Modèles en Vedette</h2>
                  <p>
                    Une collection haut de gamme de voitures de luxe exceptionnelles, soigneusement sélectionnées et prêtes à vous offrir une expérience unique, comme si elles étaient les vôtres.
                  </p>
                  <div class="owl-nav slide-nav-1 nav-control"></div>
                </div>
              </div>
              <div class="col-lg-8">
                <div class="top-rated-cars-slider">
                  <owl-carousel-o
                    [options]="ProfessionalOwlOptions"
                  
                  >
                    <ng-template carouselSlide>
                    <!-- owl carousel item -->
                    <div class="rental-car-item">
                      <div class="listing-item mb-0">										
                        <div class="listing-img">
                          <a [routerLink]="routes.listingDetails">
                            <img src="assets/img/cars/car-08-slide3.jpg" class="img-fluid" alt="Toyota">
                          </a>
                          <div class="fav-item justify-content-end">
                            <a href="javascript:void(0)" class="fav-icon" (click)="toggleClass(0)"
                            (keydown)="toggleClass(0)"
                            tabindex="1"
                            [ngClass]="{ selected: isClassAdded[0] }">
                              <i class="feather icon-heart"></i>
                            </a>										
                          </div>
                        </div>										
                        <div class="listing-content">
                          <div class="listing-features">												
                            <div class="fav-item-rental">
                              <div class="featured-text">800 DH<span> /Jour</span></div>					
                            </div>																  
                            <div class="list-rating">							
                              <i class="fas fa-star filled"></i>
                              <i class="fas fa-star filled"></i>
                              <i class="fas fa-star filled"></i>
                              <i class="fas fa-star filled"></i>
                              <i class="fas fa-star filled"></i>
                              <span>(5.0)</span>
                            </div>													
                            <h3 class="listing-title">
                              <a [routerLink]="routes.listingDetails">BMW 640 XI Gran Turismo</a>
                            </h3>
                          </div> 
                          <div class="listing-details-group">
                            <ul>
                              <li>
                                <span><img src="assets/img/icons/car-parts-01.svg" alt="Auto"></span>
                                <p>Auto</p>
                              </li>
                              <li>
                                <span><img src="assets/img/icons/car-parts-02.svg" alt="10 KM"></span>
                                <p>10 KM</p>
                              </li>
                              <li>
                                <span><img src="assets/img/icons/car-parts-03.svg" alt="Petrol"></span>
                                <p>Diesel</p>
                              </li>
                            </ul>	
                            <ul>
                              <li>
                                <span><img src="assets/img/icons/car-parts-04.svg" alt="Power"></span>
                                <p>Power</p>
                              </li>
                              <li>
                                <span><img src="assets/img/icons/car-parts-07.svg" alt="2018"></span>
                                <p>AC</p>	
                              </li>
                              <li>
                                <span><img src="assets/img/icons/car-parts-06.svg" alt="Persons"></span>
                                <p>5 Places</p>
                              </li>
                            </ul>
                          </div>
                          <div class="listing-button">
                            <a [routerLink]="routes.listingDetails" class="btn btn-order">Réserver Maintenant</a>
                          </div>	
                        </div>
                      </div>
                    </div>
                    <!-- /owl carousel item -->

                    </ng-template>
                    <ng-template carouselSlide>
                    <!-- owl carousel item -->
                    <div class="rental-car-item">
                      <div class="listing-item mb-0">										
                        <div class="listing-img">
                          <a [routerLink]="routes.listingDetails">
                            <img src="assets/img/cars/car-10-slide1.jpg" class="img-fluid" alt="Toyota">
                          </a>
                          <div class="fav-item justify-content-end">
                            <a href="javascript:void(0)" class="fav-icon" (click)="toggleClass(0)"
                            (keydown)="toggleClass(0)"
                            tabindex="1"
                            [ngClass]="{ selected: isClassAdded[0] }">
                              <i class="feather icon-heart"></i>
                            </a>										
                          </div>
                        </div>										
                        <div class="listing-content">
                          <div class="listing-features">												
                            <div class="fav-item-rental">
                              <div class="featured-text">800 DH<span> /Jour</span></div>					
                            </div>																  
                            <div class="list-rating">							
                              <i class="fas fa-star filled"></i>
                              <i class="fas fa-star filled"></i>
                              <i class="fas fa-star filled"></i>
                              <i class="fas fa-star filled"></i>
                              <i class="fas fa-star filled"></i>
                              <span>(5.0)</span>
                            </div>													
                            <h3 class="listing-title">
                              <a [routerLink]="routes.listingDetails">BMW 640 XI Gran Turismo</a>
                            </h3>
                          </div> 
                          <div class="listing-details-group">
                            <ul>
                              <li>
                                <span><img src="assets/img/icons/car-parts-01.svg" alt="Auto"></span>
                                <p>Auto</p>
                              </li>
                              <li>
                                <span><img src="assets/img/icons/car-parts-02.svg" alt="10 KM"></span>
                                <p>10 KM</p>
                              </li>
                              <li>
                                <span><img src="assets/img/icons/car-parts-03.svg" alt="Petrol"></span>
                                <p>Diesel</p>
                              </li>
                            </ul>	
                            <ul>
                              <li>
                                <span><img src="assets/img/icons/car-parts-04.svg" alt="Power"></span>
                                <p>Power</p>
                              </li>
                              <li>
                                <span><img src="assets/img/icons/car-parts-07.svg" alt="2018"></span>
                                <p>AC</p>	
                              </li>
                              <li>
                                <span><img src="assets/img/icons/car-parts-06.svg" alt="Persons"></span>
                                <p>5 Places</p>
                              </li>
                            </ul>
                          </div>
                          <div class="listing-button">
                            <a [routerLink]="routes.listingDetails" class="btn btn-order">Réserver Maintenant</a>
                          </div>	
                        </div>
                      </div>
                    </div>
                    <!-- /owl carousel item -->
                    </ng-template>
                  </owl-carousel-o>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </section>
  <!-- Top Features Cars -->

    <!-- Facts By Numbers -->
  <section class="section facts-number">
    <div class="facts-left d-none">
      <img
        src="assets/img/bg/facts-left.png"
        class="img-fluid"
        alt="facts left"
      />
    </div>
    <div class="container">
      <!-- Heading title-->
      <div class="section-heading">
        <h2 class="title">TalabCar en Chiffres</h2>
        <p class="description text-black">
          Explorez notre impact : des clients satisfaits, des trajets simplifiés.
        </p>
      </div>
      <!-- /Heading title -->
      <div class="counter-group">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12 d-flex">
            <div class="count-group flex-fill">
              <div class="customer-count d-flex align-items-center">
                <div class="count-img">
                  <img src="assets/img/icons/bx-heart.svg" alt="" />
                </div>
                <div class="count-content">
                  <h4>
                    <span
                      [countUp]="16"
                      [options]="{ duration: 10 }"
                      class="counterUp"
                      >16</span
                    >K+
                  </h4>
                  <p>Happy Customers</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-12 d-flex">
            <div class="count-group flex-fill">
              <div class="customer-count d-flex align-items-center">
                <div class="count-img">
                  <img src="assets/img/icons/bx-car.svg" alt="" />
                </div>
                <div class="count-content">
                  <h4>
                    <span
                      [countUp]="2547"
                      [options]="{ duration: 10 }"
                      class="counterUp"
                      >2547</span
                    >+
                  </h4>
                  <p>Count of Cars</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-12 d-flex">
            <div class="count-group flex-fill">
              <div class="customer-count d-flex align-items-center">
                <div class="count-img">
                  <img src="assets/img/icons/bx-headphone.svg" alt="" />
                </div>
                <div class="count-content">
                  <h4>
                    <span
                      [countUp]="625"
                      [options]="{ duration: 10 }"
                      class="counterUp"
                      >625</span
                    >K+
                  </h4>
                  <p>Car Center Solutions</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-12 d-flex">
            <div class="count-group flex-fill">
              <div class="customer-count d-flex align-items-center">
                <div class="count-img">
                  <img src="assets/img/icons/bx-history.svg" alt="" />
                </div>
                <div class="count-content">
                  <h4>
                    <span
                      [countUp]="200"
                      [options]="{ duration: 10 }"
                      class="counterUp"
                      >200</span
                    >K+
                  </h4>
                  <p>Total Kilometer</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Facts By The Numbers -->


  <!-- FAQ -->
  <section class="faq-sec-two">
    <div class="container">
      <div class="faq-sec-two-inner">
        <div class="faq-items">
          <div class="section-header-two">
            <h2>FAQ – Tout Savoir</h2>
            <p>
              Toutes les informations essentielles pour faciliter votre expérience de location.
            </p>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-8">
              <div class="faq-main-items" id="faq-details">
                <!-- FAQ Item -->
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <a
                      href="javascript:void(0);"
                      class="accordion-button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      What documents do I need to rent a Car?
                    </a>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#faq-details"
                  >
                    <div class="accordion-body">
                      <div class="accordion-content">
                        <p>
                          We offer a diverse fleet of vehicles to suit every need,
                          including compact cars. You can browse our selection
                          online or contact us for assistance in choosing the right
                          vehicle for you
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /FAQ Item -->
                <!-- FAQ Item -->
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <a
                      href="javascript:void(0);"
                      class="accordion-button collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      What types of Yacht’s are available for rent?
                    </a>
                  </h2>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#faq-details"
                  >
                    <div class="accordion-body">
                      <div class="accordion-content">
                        <p>
                          We offer a diverse fleet of vehicles to suit every need,
                          including compact cars. You can browse our selection
                          online or contact us for assistance in choosing the right
                          vehicle for you
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /FAQ Item -->
                <!-- FAQ Item -->
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThree">
                    <a
                      href="javascript:void(0);"
                      class="accordion-button collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Can I rent a yacht for more than one week?
                    </a>
                  </h2>
                  <div
                    id="collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#faq-details"
                  >
                    <div class="accordion-body">
                      <div class="accordion-content">
                        <p>
                          We offer a diverse fleet of vehicles to suit every need,
                          including compact cars. You can browse our selection
                          online or contact us for assistance in choosing the right
                          vehicle for you
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /FAQ Item -->
                <!-- FAQ Item -->
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingFour">
                    <a
                      href="javascript:void(0);"
                      class="accordion-button collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      How old do I need to be to rent a Car ?
                    </a>
                  </h2>
                  <div
                    id="collapseFour"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#faq-details"
                  >
                    <div class="accordion-body">
                      <div class="accordion-content">
                        <p>
                          We offer a diverse fleet of vehicles to suit every need,
                          including compact cars. You can browse our selection
                          online or contact us for assistance in choosing the right
                          vehicle for you
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /FAQ Item -->
                <!-- FAQ Item -->
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingFive">
                    <a
                      href="javascript:void(0);"
                      class="accordion-button collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      Can I rent a Car with a debit card?
                    </a>
                  </h2>
                  <div
                    id="collapseFive"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingFive"
                    data-bs-parent="#faq-details"
                  >
                    <div class="accordion-body">
                      <div class="accordion-content">
                        <p>
                          We offer a diverse fleet of vehicles to suit every need,
                          including compact cars. You can browse our selection
                          online or contact us for assistance in choosing the right
                          vehicle for you
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /FAQ Item -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /FAQ -->

  <!-- Client Reviews -->
  <section class="our-client-review-sec">
    <div class="sec-round-colors">
      <span class="bg-orange round-small"></span>
    </div>
    <div class="container">
      <div class="section-header-two">
        <h2>Ce que Nos Clients Disent</h2>
        <p>Découvrez l'avis de nos clients sur nos services</p>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6 d-flex">
          <div class="client-review-card flex-fill">
            <div class="client-review-content">
              <div class="client-img">
                <a href="javascript:void(0);" class="img-avatar"
                  ><img src="assets/img/profiles/avatar-16.jpg" alt="Img"
                /></a>
                <p>
                  Louer une voiture chez TalabCar a rendu nos vacances en famille inoubliables, avec un service client exceptionnel. Le SUV spacieux que nous avons loué a accueilli toute la famille et nos bagages avec confort, offrant un voyage fluide tout au long de notre séjour.
                </p>
                <h5><a href="javascript:void(0);">Rabien Ustoc</a></h5>
                <span>Casablanca, Maroc</span>
                <div class="quataion-mark">
                  <img
                    src="assets/img/icons/quatation-mark.svg"
                    class="img-fluid"
                    alt="Img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 d-flex">
          <div class="client-review-card flex-fill">
            <div class="client-review-content">
              <div class="client-img">
                <a href="javascript:void(0);" class="img-avatar"
                  ><img src="assets/img/profiles/avatar-17.jpg" alt="Img"
                /></a>
                <p>
                  Louer une voiture chez TalabCar a rendu nos vacances en famille inoubliables, avec un service client exceptionnel. Le SUV spacieux que nous avons loué a accueilli toute la famille et nos bagages avec confort, offrant un voyage fluide tout au long de notre séjour.
                </p>
                <h5><a href="javascript:void(0);">Adrian Tres</a></h5>
                <span>Fès, Maroc</span>
                <div class="quataion-mark">
                  <img
                    src="assets/img/icons/quatation-mark.svg"
                    class="img-fluid"
                    alt="Img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 d-flex">
          <div class="client-review-card flex-fill">
            <div class="client-review-content">
              <div class="client-img">
                <a href="javascript:void(0);" class="img-avatar"
                  ><img src="assets/img/profiles/avatar-18.jpg" alt="Img"
                /></a>
                <p>
                  En tant que voyageur d’affaires fréquent, je fais confiance à TalabCar pour tous mes besoins. Leur large choix de véhicules, leurs emplacements pratiques, et leurs prix compétitifs font d’eux mon premier choix à chaque fois. De plus, leur service client est irréprochable !
                </p>
                <h5><a href="javascript:void(0);">Mariana Fauzel</a></h5>
                <span>Tanger, Maroc</span>
                <div class="quataion-mark">
                  <img
                    src="assets/img/icons/quatation-mark.svg"
                    class="img-fluid"
                    alt="Img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="view-all text-center">
            <a [routerLink]="routes.testimonial" class="btn btn-secondary"
              >Voir tous les témoignage</a
            >
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Client Review -->

    <!-- Full Banner -->
  <section class="full-banner">
    <div class="container">
      <div class="sec-title">
        <h2>Agence de location de voitures ?</h2>
        <p>
          Rejoignez TalabCar et optimisez la visibilité de votre flotte pour atteindre plus de clients partout au Maroc.
        </p>
        <div class="sec-btns">
          <a [routerLink]="routes.login" class="btn btn-dark-blue">Commencer</a>
          <a
            [routerLink]="routes.listingDetails"
            class="btn btn-primary d-flex align-items-center"
            >En savoir plus</a
          >
        </div>
      </div>
    </div>
  </section>
  <!-- /Full Banner -->
  </div>
  
  <app-footer></app-footer>
</div>
