import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { BsDaterangepickerDirective } from 'ngx-bootstrap/datepicker';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { routes } from 'src/app/shared/routes/routes';
import { DataService } from 'src/app/shared/services/data/data.service';
import { carTypes, testimonial, testimonials, thumbnails1 } from 'src/app/shared/services/model/model';
interface data {
  value: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements AfterViewInit{
  public routes = routes
  showTimePicker: Array<string> = [];
  myTime: Date = new Date();
  myTime2: Date = new Date();
  public thumbnails1: thumbnails1[] = [];
  public testimonials: testimonials[] = [];
  public testimonial: testimonial[] = [];
  public carTypes: carTypes[] = [];
  selectedLocation: string | null = null;
  selectedDateRange: string | null = null;
  isLocationFieldFocused: boolean = false;
  isDateFieldFocused: boolean = false;
  location: string = '';
  startDate: Date | undefined;
  endDate: Date | undefined;


// TODO: FOR TEST
  public cities: string[] = [
    'Casablanca',
    'Marrakech',
    'Rabat',
    'Tangier',
    'Agadir',
    'Fes',
    'Meknes',
  ];

  @ViewChild('dateRangePickerInput') dateRangePickerInput!: BsDaterangepickerDirective;
  dateRange: Date[] | undefined;


  ngOnInit(): void {
    this.setDefaultDateRange();
  }


  // test selection
  onSelect(event: any): void {
    console.log('Selected location:', event.item);
  }

   // Method to track focus state for city field
   onLocationFieldFocus() {
    this.isLocationFieldFocused = true;
  }

  onLocationFieldBlur() {
    this.isLocationFieldFocused = false;
  }

  // Method to track focus state for date field
  onDateFieldFocus() {
    this.isDateFieldFocused = true;
  }

  onDateFieldBlur() {
    this.isDateFieldFocused = false;
  }

  setDefaultDateRange(): void {
    const today = new Date();
    const threeDaysLater = new Date(today);
    threeDaysLater.setDate(today.getDate() + 3);

    this.startDate = today;
    this.endDate = threeDaysLater;
    this.updateDateRangeDisplay();
  }

  onDateSelected(value: (Date | undefined)[] | undefined): void {
    if (value && value.length === 2 && value[0] && value[1]) {
      const [startDate, endDate] = value as Date[];
      this.selectedDateRange = `${this.formatDate(startDate)} - ${this.formatDate(endDate)}`;
    } else {
      this.selectedDateRange = null;
    }
  }

  onLocationSelect(event: any): void {
    this.selectedLocation = event.item;
  }

  clearLocation(event: Event) {
    event.preventDefault(); 
    this.selectedLocation = null;
    console.log(this.selectedLocation, 'clear location')
  }

  clearDateRange(): void {
    this.startDate = undefined;
    this.endDate = undefined;
    this.selectedDateRange = 'Add dates'; // Reset the label to the placeholder | TODO: Dynamic
  }
  formatDate(date: Date): string {
    const options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }
  updateDateRangeDisplay(): void {
    if (this.startDate && this.endDate) {
      const formattedStartDate = this.formatDate(this.startDate);
      const formattedEndDate = this.formatDate(this.endDate);
      this.selectedDateRange = `${formattedStartDate} - ${formattedEndDate}`;
    } else {
      this.selectedDateRange = 'Add dates'; // TODO: Dynamic
    }
  }

  constructor(private data: DataService, private datePipe: DatePipe) {
    this.testimonials = this.data.testimonials;
    this.thumbnails1 = this.data.thumbnails1;
    this.testimonial = this.data.testimonial;
    this.carTypes = this.data.carTypes;
    
  }
  carOwlOptions: OwlOptions = {
    loop:true,
    margin:24,
    nav:true,
    dots: false,
    autoplay:false,
    navText : ["<i class='bx bx-chevron-left'></i>","<i class='bx bx-chevron-right'></i>"],
    responsive:{
      0:{
        items:1
      },
      
      550:{
        items:1
      },
      700:{
        items:2
      },
      1000:{
        items:7
      },
      1200:{
        items:7
      }
    }
  };
  testimonialOptions: OwlOptions = {
    loop: true,
    margin: 24,
    nav: false,
    dots: true,
    autoplay: true,
    smartSpeed: 2000,
    navText: [
      "<i class='fa-solid fa-angle-left'></i>",
      "<i class='fa-solid fa-angle-right'></i>",
    ],
    responsive: {
      0: {
        items: 1,
      },

      550: {
        items: 1,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 2,
      },
    },
  };

  bikefeatureOwlOptions: OwlOptions = {
    loop:true,
    margin:24,
    nav:true,
    dots: false,
    autoplay:false,
    smartSpeed: 2000,
    items:1,
    navText : ["<i class='bx bx-chevron-left'></i>","<i class='bx bx-chevron-right'></i>"],
    responsive:{
      0:{
        items:1
      },
      
      550:{
        items:1
      },
      700:{
        items:2
      },
      1000:{
        items:3
      },
      1400:{
        items:4
      }
    },
  };
  blogsliderOwlOptions: OwlOptions = {
    loop:true,
			margin:24,
			nav:true,
			dots:false,
			smartSpeed: 2000,
			autoplay:false,
      navText: [
				'<i class="fa-solid fa-chevron-left"></i>',
				'<i class="fa-solid fa-chevron-right"></i>'
			],
    responsive:{
      0:{
        items:1
      },				
      550:{
        items:1
      },
      768:{
        items:3
      },
      1200:{
        items:3
      }
    }
  };
  brandsliderOwlOptions: OwlOptions = {
    loop:true,
			margin:24,
			nav:false,
			dots:false,
			smartSpeed: 2000,
			autoplay:false,
			navText: [
				'<i class="fa-solid fa-chevron-left"></i>',
				'<i class="fa-solid fa-chevron-right"></i>'
			],
      responsive:{
				0:{
					items:1
				},				
				550:{
					items:3
				},
				768:{
					items:4
				},
				1000:{
					items:5
				},
				1200:{
					items:7
				}
			}
  };
  recommendOwlOptions: OwlOptions = {
    loop:true,
			margin:24,
			nav:false,
			dots: false,
			autoplay:true,
			center: true,
			smartSpeed: 2000,
      responsive:{
				0:{
					items:1
				},
				
				767:{
					items:1
				},
				992:{
					items:3
				},
				1400:{
					items:3
				}
			}
  };
  imageOwlOptions: OwlOptions = {
    loop:true,
    margin:27,
    nav:true,
    dots:true,
    smartSpeed: 2000,
    autoplay:false,
    navText: [
      '<i class="fa-solid fa-chevron-left"></i>',
      '<i class="fa-solid fa-chevron-right"></i>'
    ],
    responsive:{
      0:{
        items:1
      },				
      550:{
        items:1
      },
      768:{
        items:1
      },
      1000:{
        items:1
      }
    }
  };
  carTypesOptions: OwlOptions = {
    loop: true,
    margin: 24,
    nav: true,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    navText: [
      "<i class='fa-solid fa-arrow-left'></i>",
      "<i class='fa-solid fa-arrow-right'></i>",
    ],
    responsive: {
      0: {
        items: 1,
      },

      550: {
        items: 1,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 5,
      },
      1200: {
        items: 5,
      },
    },
  };

  ProfessionalOwlOptions: OwlOptions = {
    loop:true,
			margin:24,
			nav:true,
			dots:true,
			autoplay:false,
      navText : ["<i class='bx bx-chevron-left'></i>","<i class='bx bx-chevron-right'></i>"],
      responsive:{
				0:{
					items:1
				},				
				550:{
					items:3
				},
				768:{
					items:4
				},
				1000:{
					items:5
				},
				1200:{
					items:5

				}
			}
  };


  toggleTimePicker(value: string): void {
    if (this.showTimePicker[0] !== value) {
      this.showTimePicker[0] = value;
    } else {
      this.showTimePicker = [];
    }
  }
  formatTime(date: Date) {
    const selectedDate: Date = new Date(date);
    return this.datePipe.transform(selectedDate, 'h:mm a');
  }

  public selectedValue1!: string;
  public selectedValue2!: string;
  public selectedValue3!: string;
  selectedList1: data[] = [
    { value: 'Cruiser' },
    { value: 'Scooters' },
    
  ];
  selectedList2: data[] = [
    { value: 'KTM 300' },
    { value: 'KTM RC 390' },
    
  ];
  selectedList3: data[] = [
    { value: 'Newyork' },
    { value: 'Los Angeles' },
    
  ];
  carimagesliderOwlOptions: OwlOptions = {
    loop:true,
    margin:24,
    nav:true,
    dots: true,
    autoplay:false,
    smartSpeed: 2000,
    navText : ["<i class='bx bx-chevron-left'></i>","<i class='bx bx-chevron-right'></i>"],
    responsive:{
      0:{
        items:1
      },
      
      550:{
        items:1
      },
      700:{
        items:2
      },
      1000:{
        items:6
      },
      1200:{
        items:6
      }
    }
  };
  // public slideConfig = {
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   arrows: false,
  //   fade: true,
   
    
  // };
  public slideConfig2 = {
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: '.testimonial-slider',
    dots: false,
    arrows: false,
    centerMode: false,
    focusOnSelect: true,
    
  };
  carbrandOwlOptions: OwlOptions = {
    loop:true,
    margin:24,
    nav:false,
    dots: false,
    autoplay:false,
    smartSpeed: 2000,
    navText : ["<i class='bx bx-chevron-left'></i>","<i class='bx bx-chevron-right'></i>"],
    responsive:{
      0:{
        items:1
      },
      
      550:{
        items:1
      },
      700:{
        items:2
      },
      1000:{
        items:7
      },
      1200:{
        items:7
      }
    }
  };
  public ngAfterViewInit(): void{
    window.dispatchEvent(new Event('resize'))
  }
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    dots: false,
    arrows: false,
    asNavFor: '.testimonial-thumbnails'
  };

  thumbnailConfig = {
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: '.testimonial-slider',
    focusOnSelect: true
  };
  toggleClass(index: number) {
    this.isClassAdded[index] = !this.isClassAdded[index];
  }
  public isClassAdded: boolean[] = [false];
}
