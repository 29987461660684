<!-- Footer -->
<footer class="footer">
    <!-- Footer Top -->	
    <div class="footer-top">
       <div class="container">
          <div class="row">
             <div class="col-lg-3">
                <div class="footer-contact footer-widget">
                   <div class="footer-logo">
                      <img src="assets/img/logoipsum.svg" class="img-fluid aos" alt="logo">
                   </div>
                   <div class="footer-contact-info">
                      <h6>Partez en toute confiance.<br> <span>TalabCar</span> est là pour vos déplacements.</h6>
                      <div class="footer-address">
                         <div class="addr-info">
                            <a href="tel:+1(888)7601940"><i class="bx bxs-phone"></i>(+212) 05 55 67 55 33</a>
                         </div>
                      </div>
                      <div class="footer-address pb-4">
                         <div class="addr-info">
                            <a href="mailto:support&#64;example.com"><i class="bx bxs-envelope"></i>support&#64;talabcar.com</a>
                         </div>
                      </div>
                      <div class="footer-social-widget">
                         <ul class="nav-social">
                            <li>
                               <a href="javascript:void(0)"><i class="fa-brands fa-facebook-f fa-facebook fi-icon"></i></a>
                            </li>
                            <li>
                               <a href="javascript:void(0)"><i class="fab fa-instagram fi-icon"></i></a>
                            </li>
                            <li>
                               <a href="javascript:void(0)"><i class="fab fa-twitter fi-icon"></i> </a>
                            </li>
                         </ul>
                      </div>
                   </div>
                </div>
             </div>
             <div class="col-lg-2 col-md-6 offset-lg-3">
                <!-- Footer Widget -->
                <div class="footer-widget footer-menu">
                   <h5 class="footer-title">Mon compte</h5>
                   <ul>
                      <li>
                         <a href="javascript:void(0)">Me connecter</a>
                      </li>
                      <li>
                         <a href="javascript:void(0)">Nous contacter</a>
                      </li>
                      <li>
                         <a href="javascript:void(0)">Centre d'aide</a>
                      </li>
                   </ul>
                </div>
                <!-- /Footer Widget -->
             </div>
             <div class="col-lg-2 col-md-6">
                <!-- Footer Widget -->
                <div class="footer-widget footer-menu">
                   <h5 class="footer-title">À propos</h5>
                   <ul>
                      <li>
                         <a href="javascript:void(0)">Tout savoir sur TalabCar</a>
                      </li>
                      <li>
                         <a href="javascript:void(0)">Rejoignez-nous !</a>
                      </li>
                      <li>
                         <a href="javascript:void(0)">Devenir partenaire</a>
                      </li>
                   </ul>
                </div>
                <!-- /Footer Widget -->
             </div>
             <div class="col-lg-2 col-md-6">
                <!-- Footer Widget -->
                <div class="footer-widget footer-menu">
                   <h5 class="footer-title">Legal</h5>
                   <ul>
                      <li>
                         <a href="javascript:void(0)">Mentions légales</a>
                      </li>
                      <li>
                         <a href="javascript:void(0)">Conditions d'utilisation</a>
                      </li>
                      <li>
                         <a href="javascript:void(0)">Politique de confidentialité</a>
                      </li>
                   </ul>
                </div>
                <!-- /Footer Widget -->
             </div>
          </div>
       </div>
    </div>
    <!-- /Footer Top -->
    <!-- Footer Bottom -->
    <div class="footer-bottom">
       <div class="container">
          <!-- Copyright -->
          <div class="copyright">
             <div class="row align-items-center">
                <div class="col-lg-6">
                   <div class="copyright-text">
                      <p>© 2024 TalabCar Inc.</p>
                   </div>
                </div>
                <div class="col-lg-6">
                   <div class="footer-list">
                      <ul>
                         <!--Not in use: Country Flag-->
                         <li class="country-flag d-none">
                            <div class="dropdown">
                               <a class="dropdown-toggle nav-tog" data-bs-toggle="dropdown" >
                               <img [src]="currentLang === 'en' ? 'assets/img/flags/us.png' : 'assets/img/flags/fr.png'" alt="Img">
                               {{ currentLang === 'en' ? 'English' : 'Français' }}
                               </a>
                               <div class="dropdown-menu dropdown-menu-end">
                                  <a *ngIf="currentLang === 'en'" class="dropdown-item" (click)="changeLanguage('fr')">
                                  <img src="assets/img/flags/fr.png" alt="Img">Français
                                  </a>
                                  <a *ngIf="currentLang === 'fr'" class="dropdown-item" (click)="changeLanguage('en')">
                                  <img src="assets/img/flags/us.png" alt="Img">English
                                  </a>
                               </div>
                            </div>
                         </li>
                         <li>
                            <div class="vistors-details">
                               <ul class="d-flex">
                                  <li><a href="javascript:void(0)"><img class="img-fluid" src="assets/img/icons/paypal.svg" alt="Paypal"></a></li>
                                  <li><a href="javascript:void(0)"><img class="img-fluid" src="assets/img/icons/visa.svg" alt="Visa"></a></li>
                                  <li><a href="javascript:void(0)"><img class="img-fluid" src="assets/img/icons/master.svg" alt="Master"></a></li>
                                  <li><a href="javascript:void(0)"><img class="img-fluid" src="assets/img/icons/applegpay.svg" alt="applegpay"></a></li>
                               </ul>
                            </div>
                            <!--Not in use: Social Icons-->
                            <div class="footer-social-widget d-none">
                               <ul class="nav-social">
                                  <li>
                                     <a href="javascript:void(0)"><i class="fa-brands fa-facebook-f fa-facebook fi-icon"></i></a>
                                  </li>
                                  <li>
                                     <a href="javascript:void(0)"><i class="fab fa-instagram fi-icon"></i></a>
                                  </li>
                                  <li>
                                     <a href="javascript:void(0)"><i class="fab fa-twitter fi-icon"></i> </a>
                                  </li>
                               </ul>
                            </div>
                         </li>
                      </ul>
                   </div>
                </div>
             </div>
          </div>
          <!-- /Copyright -->
       </div>
    </div>
    <!-- /Footer Bottom -->			
 </footer>
 <!-- /Footer -->