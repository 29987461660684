<header class="header">
    <div class="container-fluid">
        <nav class="navbar navbar-expand-lg header-nav">
            <div class="navbar-header">
                <a  (click)="toggleSidebar()" id="mobile_btn" href="javascript:void(0);">
                    <span class="bar-icon">
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                </a>
                <a [routerLink]="routes.home" class="navbar-brand logo">
                  <!--TODO: To be changed-->
                    <img src="assets/img/logoipsum.svg" class="img-fluid" alt="Logo">
                </a>
                <a [routerLink]="routes.home" class="navbar-brand logo-small">
                    <img src="assets/img/logoipsum.svg" class="img-fluid" alt="Logo">
                </a>					
            </div>
            <div class="main-menu-wrapper">
                <div class="menu-header">
                  <a [routerLink]="routes.home" class="menu-logo">
                    <img src="assets/img/apple.svg" class="img-fluid" alt="Logo" />
                  </a>
                  <a
                    (click)="hideSidebar()"
                    id="menu_close"
                    class="menu-close"
                    href="javascript:void(0);"
                    >
                    <i class="fas fa-times"></i
                    ></a>
                  </div>
                  <ul class="main-nav">
                    @for (mainMenus of header; track mainMenus) {
                      @if (mainMenus.separateRoute === true) {
                        <li routerLinkActive="active">
                          <a [routerLink]="mainMenus.routes">{{ mainMenus.tittle }}</a>
                        </li>
                      }
                      @if (mainMenus.separateRoute === false) {
                        <li class="has-submenu" routerLinkActive="active">
                          <a
                            href="javascript:void(0);"
                            (click)="mainMenus.showAsTab = !mainMenus.showAsTab"
                            >{{ mainMenus.tittle }} <i class="fas fa-chevron-down"></i
                          ></a>
                          <ul
                            class="submenu"
                          [ngClass]="{
                            'd-block': mainMenus.showAsTab === true
                          }"
                            >
                            @for (menu of mainMenus.menu; track menu) {
                              @if (menu.hasSubRoute === false) {
                                <li routerLinkActive="active">
                                  <a [routerLink]="menu.routes">{{ menu.menuValue }}</a>
                                </li>
                              }
                              @if (menu.hasSubRoute === true) {
                                <li class="has-submenu" routerLinkActive="active">
                                  <a
                                    (click)="menu.showSubRoute = !menu.showSubRoute"
                                    [routerLink]="menu.routes"
                                    >{{ menu.menuValue }}</a
                                    >
                                    <ul
                                      class="submenu"
                                  [ngClass]="{
                                    'd-block': menu.showSubRoute === true
                                  }"
                                      >
                                      @for (subMenu of menu.subMenus; track subMenu) {
                                        <li routerLinkActive="active">
                                          <a [routerLink]="subMenu.routes">{{
                                            subMenu.menuValue
                                          }}</a>
                                        </li>
                                      }
                                    </ul>
                                  </li>
                                }
                              }
                            </ul>
                          </li>
                        }
                      }
                    </ul>
                  </div>
            <ul class="nav header-navbar-rht">
                <li class="nav-item user-link">
                    <a class="nav-link header-login" [routerLink]="routes.register">
                      Se connecter
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link header-reg" [routerLink]="routes.listingList"><span><i class="bx bx-plus-circle"></i></span>{{ "homepage.header.button" | translate }}</a>
                </li>
            </ul>
        </nav>
    </div>
</header>