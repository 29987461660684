import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSortModule } from '@angular/material/sort';
import {
  BsDatepickerConfig,
  BsDatepickerModule,
} from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { CountUpModule } from 'ngx-countup';
import { LightboxModule } from 'ngx-lightbox';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { materialModule } from './material.module';
//import { CustomPaginationModule } from './custom-pagination/custom-pagination.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FullCalendarModule } from '@fullcalendar/angular';
import { LightgalleryModule } from 'lightgallery/angular';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ModalComponent } from './components/modal/modal.component';

@NgModule({
  declarations: [
    ModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    materialModule,
    CountUpModule,
    CarouselModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    SlickCarouselModule,
    LightboxModule,
    TimepickerModule,
    MatSliderModule,
    HttpClientModule,
    MatSortModule,
    BsDatepickerModule.forRoot(),
    //CustomPaginationModule,
    MatTooltipModule,
    GoogleMapsModule,
    LightgalleryModule,
    FullCalendarModule,
    TypeaheadModule.forRoot()

  ],
  exports: [
    ModalComponent,
    CommonModule,
    materialModule,
    CountUpModule,
    CarouselModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule,
    SlickCarouselModule,
    LightboxModule,
    TimepickerModule,
    MatSliderModule,
    HttpClientModule,
    MatSortModule,
    //CustomPaginationModule,
    MatTooltipModule,
    GoogleMapsModule,
    LightgalleryModule,
    FullCalendarModule,
    TypeaheadModule
    
  ],
  providers: [DatePipe, BsDatepickerConfig],
})
export class SharedModule {}
