import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { routes } from 'src/app/shared/routes/routes';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit, OnDestroy {
  currentLang: string | undefined;
  private subSink: SubSink = new SubSink();

  constructor(private readonly translate: TranslateService) {
    this.currentLang = this.translate.currentLang || 'fr';
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe(); 
  }

  ngOnInit(): void {
    // Subscribe to language change events
    this.subSink.add(
      this.translate.onLangChange.subscribe(event => {
        this.currentLang = event.lang;
      })
    );
  }

  changeLanguage(language: string) {
    this.translate.use(language);
  }
  
  public routes = routes

}
