<!-- Completed Booking -->
<div class="modal new-modal multi-step fade" id="complete_booking" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close-btn" data-bs-dismiss="modal"><span>×</span></button>
            </div>
            <div class="modal-body">
                <div class="booking-header">
                    <div class="booking-img-wrap">
                        <div class="book-img">
                            <img src="assets/img/cars/car-05.jpg" alt="img">
                        </div>
                        <div class="book-info">
                            <h6>Chevrolet Camaro</h6>
                            <p><i class="feather icon-map-pin"></i> Location : Miami St, Destin, FL 32550, USA</p>
                        </div>
                    </div>
                    <div class="book-amount">
                        <p>Total Amount</p>
                        <h6>$4700 <a href="javascript:void(0);"><i class="feather icon-alert-circle"></i></a></h6>
                    </div>
                </div>
                <div class="booking-group">
                    <div class="booking-wrapper">
                        <div class="booking-title">
                            <h6>Booking Details</h6>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="booking-view">
                                    <h6>Booking Type</h6>
                                    <p>Delivery</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="booking-view">
                                    <h6>Rental Type</h6>
                                    <p>Days (3 Days)</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="booking-view">
                                    <h6>Extra Service</h6>
                                    <p>Mobile Charging</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="booking-view">
                                    <h6>Delivery</h6>
                                    <p>45, Avenue ,Mark Street, USA</p>
                                    <p>11 Jan 2023, 03:30 PM</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="booking-view">
                                    <h6>Dropoff</h6>
                                    <p>78, 10th street Laplace,USA</p>
                                    <p>11 Jan 2023, 03:30 PM</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="booking-view">
                                    <h6>Status</h6>
                                    <span class="badge badge-light-success">Completed</span>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="booking-view">
                                    <h6>Booked On</h6>
                                    <p>15 Sep 2023, 09:30 AM</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="booking-view">
                                    <h6>Start Date</h6>
                                    <p>18 Sep 2023, 09:30 AM</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="booking-view">
                                    <h6>End Date</h6>
                                    <p>20 Sep 2023, 09:30 AM</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="booking-wrapper">
                        <div class="booking-title">
                            <h6>Personal Details</h6>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="booking-view">
                                    <h6>Details</h6>
                                    <p>Johna Melinda</p>
                                    <p>+1 56441 56464</p>
                                    <p>Johna&#64;example.com</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="booking-view">
                                    <h6>Address</h6>
                                    <p>78, 10th street</p>
                                    <p>Laplace,USA</p>
                                    <p>316 654</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="booking-view">
                                    <h6>No of Person’s</h6>
                                    <p>2 Adults, 1 Child</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-btn modal-btn-sm text-end">
                    <a href="javascript:void(0);" data-bs-target="#start_ride" data-bs-toggle="modal"
                        data-bs-dismiss="modal" class="btn btn-primary">
                        Start Ride
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /Completed Booking -->

<!-- Order Success Modal -->
<div class="modal new-modal order-success-modal fade" id="start_ride" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="order-success-info">
                    <span class="order-success-icon">
                        <img src="assets/img/icons/check-icon.svg" alt="Icon">
                    </span>
                    <h4>Successful</h4>
                    <p>YYou Ride has been successfully started. Order id : <span>#50641</span></p>
                    <div class="modal-btn">
                        <a [routerLink]="routes.userDashboard" class="btn btn-secondary">
                            Go to Dashboard <i class="feather icon-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /Order Success Modal -->

<!-- Delete Modal -->
<div class="modal new-modal fade" id="delete_modal" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="delete-action">
                    <div class="delete-header">
                        <h4>Delete Booking</h4>
                        <p>Are you sure want to delete?</p>
                    </div>
                    <div class="modal-btn">
                        <div class="row">
                            <div class="col-6">
                                <a href="javascript:void(0);" data-bs-dismiss="modal" class="btn btn-secondary w-100">
                                    Delete
                                </a>
                            </div>
                            <div class="col-6">
                                <a href="javascript:void(0);" data-bs-dismiss="modal" class="btn btn-primary w-100">
                                    Cancel
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /Delete Modal -->

<!-- Custom Date Modal -->
<div class="modal new-modal fade" id="custom_date" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Custom Date</h4>
                <button type="button" class="close-btn" data-bs-dismiss="modal"><span>×</span></button>
            </div>
            <div class="modal-body">
                <form action="#">
                    <div class="modal-form-group">
                        <label>From Date <span class="text-danger">*</span></label>
                        <input type="date" class="form-control">
                    </div>
                    <div class="modal-form-group">
                        <label>To Date <span class="text-danger">*</span></label>
                        <input type="date" class="form-control">
                    </div>
                    <div class="modal-btn modal-btn-sm text-end">
                        <a href="javascript:void(0);" data-bs-dismiss="modal" class="btn btn-secondary">
                            Cancel Booking
                        </a>
                        <a href="javascript:void(0);" data-bs-dismiss="modal" class="btn btn-primary">
                            Start Ride
                        </a>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- /Custom Date Modal -->

<!-- Inprogress Booking -->
<div class="modal new-modal multi-step fade" id="inprogress_booking" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close-btn" data-bs-dismiss="modal"><span>×</span></button>
            </div>
            <div class="modal-body">
                <div class="booking-header">
                    <div class="booking-img-wrap">
                        <div class="book-img">
                            <img src="assets/img/cars/car-05.jpg" alt="img">
                        </div>
                        <div class="book-info">
                            <h6>Chevrolet Camaro</h6>
                            <p><i class="feather-map-pin"></i> Location : Miami St, Destin, FL 32550, USA</p>
                        </div>
                    </div>
                    <div class="book-amount">
                        <p>Total Amount</p>
                        <h6>$4700 <a href="javascript:void(0);"><i class="feather-alert-circle"></i></a></h6>
                    </div>
                </div>
                <div class="booking-group">
                    <div class="booking-wrapper">
                        <div class="booking-title">
                            <h6>Booking Details</h6>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 col-md-6">								
                                <div class="booking-view">
                                    <h6>Booking Type</h6>
                                    <p>Delivery</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">								
                                <div class="booking-view">
                                    <h6>Rental Type</h6>
                                    <p>Days (3 Days)</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">								
                                <div class="booking-view">
                                    <h6>Extra Service</h6>
                                    <p>Mobile Charging</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">								
                                <div class="booking-view">
                                    <h6>Delivery</h6>
                                    <p>45, Avenue ,Mark Street, USA</p>
                                    <p>11 Jan 2023, 03:30 PM</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">								
                                <div class="booking-view">
                                    <h6>Dropoff</h6>
                                    <p>78, 10th street Laplace,USA</p>
                                    <p>11 Jan 2023, 03:30 PM</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">								
                                <div class="booking-view">
                                    <h6>Status</h6>
                                    <span class="badge badge-light-warning">Inprogress</span>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">								
                                <div class="booking-view">
                                    <h6>Booked On</h6>
                                    <p>15 Sep 2023, 09:30 AM</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">								
                                <div class="booking-view">
                                    <h6>Start Date</h6>
                                    <p>18 Sep 2023, 09:30 AM</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">								
                                <div class="booking-view">
                                    <h6>End Date</h6>
                                    <p>20 Sep 2023, 09:30 AM</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="booking-wrapper">
                        <div class="booking-title">
                            <h6>Personal Details</h6>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 col-md-6">								
                                <div class="booking-view">
                                    <h6>Details</h6>
                                    <p>Johna Melinda</p>
                                    <p>+1 56441 56464</p>
                                    <p>Johna&#64;example.com</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">								
                                <div class="booking-view">
                                    <h6>Address</h6>
                                    <p>78, 10th street</p>
                                    <p>Laplace,USA</p>
                                    <p>316 654</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">								
                                <div class="booking-view">
                                    <h6>No of Person’s</h6>
                                    <p>2 Adults, 1 Child</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-btn modal-btn-sm text-end">
                    <a href="javascript:void(0);" data-bs-target="#start_ride" data-bs-toggle="modal"  data-bs-dismiss="modal" class="btn btn-primary">
                        Complete Ride
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /Inprogress Booking -->

<!-- View Invoice Modal -->
<div class="modal new-modal fade" id="view_invoice" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
            <div class="modal-header border-0 m-0 p-0">
                <div class="invoice-btns">
                    <a href="javascript:void(0)" class="btn me-2">
                        <i class="feather icon-printer"></i> Print
                    </a>
                    <a href="javascript:void(0)" class="btn">
                        <i class="feather icon-download"></i> Download Invoice
                    </a>
                </div>
            </div>
            <div class="modal-body">
                <div class="invoice-details">
                    <div class="invoice-items">
                        <div class="row align-items-center">
                            <div class="col-md-6">
                                <div class="invoice-logo">
                                    <img src="assets/img/apple.svg" alt="logo">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="invoice-info-text">
                                    <h4>Invoice</h4>
                                    <p>Invoice Number : <span>In983248782</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="invoice-item-bills">
                        <div class="row align-items-center">
                            <div class="col-lg-4 col-md-12">
                                <div class="invoice-bill-info">
                                    <h6>Billed to</h6>
                                    <p>
                                        Customer Name <br>
                                        9087484288 <br>
                                        Address line 1, <br>
                                        Address line 2 <br>
                                        Zip code ,City - Country
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12">
                                <div class="invoice-bill-info">
                                    <h6>Invoice From</h6>
                                    <p>
                                        Company Name <br>
                                        9087484288 <br>
                                        Address line 1, <br>
                                        Address line 2 <br>
                                        Zip code ,City - Country
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12">
                                <div class="invoice-bill-info border-0">
                                    <p>Issue Date : 27 Jul 2022</p>
                                    <p>Due Amount : $1,54,22 </p>
                                    <p class="mb-0">PO Number : 54515454</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="invoice-table-wrap">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <table class="invoice-table table table-center mb-0">
                                        <thead>
                                            <tr>
                                                <th>Rented Car</th>
                                                <th>No of days</th>
                                                <th>Rental Amount</th>
                                                <th class="text-end">Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <h6>Ferrari 458 MM Speciale</h6>
                                                </td>
                                                <td>7</td>
                                                <td>$2000</td>
                                                <td class="text-end">$2000</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="payment-details-info">
                        <div class="row">
                            <div class="col-lg-6 col-md-12">
                                <div class="invoice-terms">
                                    <h6>Payment Details</h6>
                                    <div class="invocie-note">
                                        <p>Debit Card<br>
                                            XXXXXXXXXXXX-2541<br>
                                            HDFC Bank</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <div class="invoice-total-box">
                                    <div class="invoice-total-inner">
                                        <p><b>Trip Amount (This does not include fuel)</b> <span>$2592</span></p>
                                        <p>Trip Protection Fees <span>+ $25</span></p>
                                        <p>Convenience Fees <span>+ $2</span></p>
                                        <p>Refundable Deposit <span>+ $2000</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="invoice-total">
                        <h4>Total <span>$4,300.00</span></h4>
                    </div>
                    <div class="invoice-note-footer">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-12">
                                <div class="invocie-note">
                                    <h6>Notes</h6>
                                    <p>Enter customer notes or any other details</p>
                                </div>
                                <div class="invocie-note mb-0">
                                    <h6>Terms and Conditions</h6>
                                    <p>Enter customer notes or any other details</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <div class="invoice-sign">
                                    <img class="img-fluid d-inline-block" src="assets/img/signature.png" alt="Sign">
                                    <span class="d-block">dreamsrent</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /View Invoice Modal -->

<!-- Delete Modal -->
<div class="modal new-modal fade" id="delete_modal_payment" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="delete-action">
                    <div class="delete-header">
                        <h4>Delete Payment History</h4>
                        <p>Are you sure want to delete?</p>
                    </div>
                    <div class="modal-btn">
                        <div class="row">
                            <div class="col-6">
                                <a href="javascript:void(0);" data-bs-dismiss="modal" class="btn btn-secondary w-100">
                                    Delete
                                </a>
                            </div>
                            <div class="col-6">
                                <a href="javascript:void(0);" data-bs-dismiss="modal" class="btn btn-primary w-100">
                                    Cancel
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="booking-wrapper">
                        <div class="booking-title">
                            <h6><span class="title-icon"><i class="fa-solid fa-location-dot"></i></span>Select Booking
                                type & Time <a href="javascript:void(0);"><i class="feather icon-alert-circle"></i></a></h6>
                        </div>
                        <div class="row">
                            <div class="col-lg-3 col-md-6">
                                <div class="modal-form-group rent-radio active">
                                    <label class="custom_radio">
                                        <input type="radio" class="rent-types" name="rent_type" checked>
                                        <span class="checkmark"></span>
                                        <span class="rent-option">Hourly</span>
                                    </label>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6">
                                <div class="modal-form-group rent-radio">
                                    <label class="custom_radio">
                                        <input type="radio" class="rent-types" name="rent_type">
                                        <span class="checkmark"></span>
                                        <span class="rent-option">Day (8 Hrs)</span>
                                    </label>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6">
                                <div class="modal-form-group rent-radio">
                                    <label class="custom_radio">
                                        <input type="radio" class="rent-types" name="rent_type">
                                        <span class="checkmark"></span>
                                        <span class="rent-option">Weekly</span>
                                    </label>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6">
                                <div class="modal-form-group rent-radio">
                                    <label class="custom_radio">
                                        <input type="radio" class="rent-types" name="rent_type">
                                        <span class="checkmark"></span>
                                        <span class="rent-option">Monthly</span>
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="modal-form-group">
                                    <label>Start Date</label>
                                    <input type="date" class="form-control">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="modal-form-group">
                                    <label>Start Time</label>
                                    <input type="time" class="form-control">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="modal-form-group">
                                    <label>Return Date</label>
                                    <input type="date" class="form-control">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="modal-form-group">
                                    <label>Return Time</label>
                                    <input type="time" class="form-control">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="booking-wrapper">
                        <div class="booking-title">
                            <h6><span class="title-icon"><i class="fa-solid fa-medal"></i></span>Extra Service</h6>
                        </div>
                        <div class="selectbox-cont">
                            <label class="custom_check w-100">
                                <input type="checkbox" name="username">
                                <span class="checkmark"></span> Baby Seat - <span class="amt">$10</span>
                            </label>
                            <label class="custom_check w-100">
                                <input type="checkbox" name="username" checked>
                                <span class="checkmark"></span> Mobile Charging - <span class="amt">$50</span>
                            </label>
                            <label class="custom_check w-100">
                                <input type="checkbox" name="username">
                                <span class="checkmark"></span> Wi-Fi Hotspot - <span class="amt">$60</span>
                            </label>
                            <label class="custom_check w-100">
                                <input type="checkbox" name="username">
                                <span class="checkmark"></span> Airport Shuttle Service - <span class="amt">$90</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="modal-btn modal-btn-sm text-end">
                    <a href="javascript:void(0);" data-bs-dismiss="modal" class="btn btn-secondary">
                        Go Back
                    </a>
                    <a href="javascript:void(0);" data-bs-dismiss="modal" class="btn btn-primary">
                        Save & Continue
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /Delete Modal -->

<!-- Delete Modal -->
<div class="modal new-modal fade" id="delete_modal_review" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="delete-action">
                    <div class="delete-header">
                        <h4>Delete Reviews</h4>
                        <p>Are you sure want to delete?</p>
                    </div>
                    <div class="modal-btn">
                        <div class="row">
                            <div class="col-6">
                                <a href="javascript:void(0);" data-bs-dismiss="modal" class="btn btn-secondary w-100">
                                    Delete
                                </a>
                            </div>
                            <div class="col-6">
                                <a href="javascript:void(0);" data-bs-dismiss="modal" class="btn btn-primary w-100">
                                    Cancel
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /Delete Modal -->

<!-- Delete Modal -->
<div class="modal new-modal fade" id="delete_modal_wallet" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="delete-action">
                    <div class="delete-header">
                        <h4>Delete Wallet History</h4>
                        <p>Are you sure want to delete?</p>
                    </div>
                    <div class="modal-btn">
                        <div class="row">
                            <div class="col-6">
                                <a href="javascript:void(0);" data-bs-dismiss="modal" class="btn btn-secondary w-100">
                                    Delete
                                </a>
                            </div>
                            <div class="col-6">
                                <a href="javascript:void(0);" data-bs-dismiss="modal" class="btn btn-primary w-100">
                                    Cancel
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /Delete Modal -->

<!-- Upcoming Booking -->
<div class="modal new-modal multi-step fade" id="upcoming_booking" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close-btn" data-bs-dismiss="modal"><span>×</span></button>
            </div>
            <div class="modal-body">
                <div class="booking-header">
                    <div class="booking-img-wrap">
                        <div class="book-img">
                            <img src="assets/img/cars/car-05.jpg" alt="img">
                        </div>
                        <div class="book-info">
                            <h6>Chevrolet Camaro</h6>
                            <p><i class="feather icon-map-pin"></i> Location : Miami St, Destin, FL 32550, USA</p>
                        </div>
                    </div>
                    <div class="book-amount">
                        <p>Total Amount</p>
                        <h6>$4700 <a href="javascript:void(0);"><i class="feather icon-alert-circle"></i></a></h6>
                    </div>
                </div>
                <div class="booking-group">
                    <div class="booking-wrapper">
                        <div class="booking-title">
                            <h6>Booking Details</h6>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="booking-view">
                                    <h6>Booking Type</h6>
                                    <p>Delivery</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="booking-view">
                                    <h6>Rental Type</h6>
                                    <p>Days (3 Days)</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="booking-view">
                                    <h6>Extra Service</h6>
                                    <p>Mobile Charging</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="booking-view">
                                    <h6>Delivery</h6>
                                    <p>45, Avenue ,Mark Street, USA</p>
                                    <p>11 Jan 2023, 03:30 PM</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="booking-view">
                                    <h6>Dropoff</h6>
                                    <p>78, 10th street Laplace,USA</p>
                                    <p>11 Jan 2023, 03:30 PM</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="booking-view">
                                    <h6>Status</h6>
                                    <span class="badge badge-light-secondary">Upcoming</span>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="booking-view">
                                    <h6>Booked On</h6>
                                    <p>15 Sep 2023, 09:30 AM</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="booking-view">
                                    <h6>Start Date</h6>
                                    <p>18 Sep 2023, 09:30 AM</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="booking-view">
                                    <h6>End Date</h6>
                                    <p>20 Sep 2023, 09:30 AM</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="booking-wrapper">
                        <div class="booking-title">
                            <h6>Personal Details</h6>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="booking-view">
                                    <h6>Details</h6>
                                    <p>Johna Melinda</p>
                                    <p>+1 56441 56464</p>
                                    <p>Johna&#64;example.com</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="booking-view">
                                    <h6>Address</h6>
                                    <p>78, 10th street</p>
                                    <p>Laplace,USA</p>
                                    <p>316 654</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="booking-view">
                                    <h6>No of Person’s</h6>
                                    <p>2 Adults, 1 Child</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /Upcoming Booking -->
<!-- Delete Modal -->
<div class="modal new-modal fade" id="delete_modal_user" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="delete-action">
                    <div class="delete-header">
                        <h4>Delete Payment History</h4>
                        <p>Are you sure want to delete?</p>
                    </div>
                    <div class="modal-btn">
                        <div class="row">
                            <div class="col-6">
                                <a href="javascript:void(0);" data-bs-dismiss="modal" class="btn btn-secondary w-100">
                                    Delete
                                </a>
                            </div>
                            <div class="col-6">
                                <a href="javascript:void(0);" data-bs-dismiss="modal" class="btn btn-primary w-100">
                                    Cancel
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /Delete Modal -->

<!-- Edit Booking -->
<div class="modal new-modal multi-step fade" id="edit_booking" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header border-0 pb-0">
                <button type="button" class="close-btn" data-bs-dismiss="modal"><span>×</span></button>
                <div class="badge-item w-100 text-end">
                    <span class="badge badge-light-warning">Inprogress</span>
                </div>
            </div>
            <div class="modal-body">
                <div class="booking-header">
                    <div class="booking-img-wrap">
                        <div class="book-img">
                            <img src="assets/img/cars/car-05.jpg" alt="img">
                        </div>
                        <div class="book-info">
                            <h6>Chevrolet Camaro</h6>
                            <p><i class="feather-map-pin"></i> Location : Miami St, Destin, FL 32550, USA</p>
                        </div>
                    </div>
                    <div class="book-amount">
                        <p>Total Amount</p>
                        <h6>$4700 <a href="javascript:void(0);"><i class="feather-alert-circle"></i></a></h6>
                    </div>
                </div>
                <div class="booking-group">
                    <div class="booking-wrapper">
                        <div class="booking-title">
                            <h6>Select Location</h6>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="loc-wrap">
                                    <div class="modal-form-group loc-item">
                                        <label>Delivery Location</label>
                                        <input type="text" class="form-control" placeholder="Enter Location">
                                    </div>
                                    <div class="modal-form-group">
                                        <label class="d-sm-block">&nbsp;</label>
                                        <a href="javascript:void(0);" class="btn btn-secondary"><i
                                                class="fa-solid fa-location-crosshairs"></i> Current Location</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="modal-form-group">
                                    <label>Dropoff Location</label>
                                    <input type="text" class="form-control" value="78, 10th street Laplace USA">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="booking-wrapper">
                        <div class="booking-title">
                            <h6><span class="title-icon"><i class="fa-solid fa-location-dot"></i></span>Select
                                Booking type & Time <a href="javascript:void(0);"><i
                                        class="feather-alert-circle"></i></a></h6>
                        </div>
                        <div class="row">
                            <div class="col-lg-3 col-md-6">
                                <div class="modal-form-group rent-radio active">
                                    <label class="custom_radio">
                                        <input type="radio" class="rent-types" name="rent_type" checked>
                                        <span class="checkmark"></span>
                                        <span class="rent-option">Hourly</span>
                                    </label>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6">
                                <div class="modal-form-group rent-radio">
                                    <label class="custom_radio">
                                        <input type="radio" class="rent-types" name="rent_type">
                                        <span class="checkmark"></span>
                                        <span class="rent-option">Day (8 Hrs)</span>
                                    </label>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6">
                                <div class="modal-form-group rent-radio">
                                    <label class="custom_radio">
                                        <input type="radio" class="rent-types" name="rent_type">
                                        <span class="checkmark"></span>
                                        <span class="rent-option">Weekly</span>
                                    </label>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6">
                                <div class="modal-form-group rent-radio">
                                    <label class="custom_radio">
                                        <input type="radio" class="rent-types" name="rent_type">
                                        <span class="checkmark"></span>
                                        <span class="rent-option">Monthly</span>
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="modal-form-group">
                                    <label>Start Date</label>
                                    <input type="date" class="form-control">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="modal-form-group">
                                    <label>Start Time</label>
                                    <input type="time" class="form-control">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="modal-form-group">
                                    <label>Return Date</label>
                                    <input type="date" class="form-control">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="modal-form-group">
                                    <label>Return Time</label>
                                    <input type="time" class="form-control">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="booking-wrapper">
                        <div class="booking-title">
                            <h6><span class="title-icon"><i class="fa-solid fa-medal"></i></span>Extra Service</h6>
                        </div>
                        <div class="selectbox-cont">
                            <label class="custom_check w-100">
                                <input type="checkbox" name="username">
                                <span class="checkmark"></span> Baby Seat - <span class="amt">$10</span>
                            </label>
                            <label class="custom_check w-100">
                                <input type="checkbox" name="username" checked>
                                <span class="checkmark"></span> Mobile Charging - <span class="amt">$50</span>
                            </label>
                            <label class="custom_check w-100">
                                <input type="checkbox" name="username">
                                <span class="checkmark"></span> Wi-Fi Hotspot - <span class="amt">$60</span>
                            </label>
                            <label class="custom_check w-100">
                                <input type="checkbox" name="username">
                                <span class="checkmark"></span> Airport Shuttle Service - <span class="amt">$90</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="modal-btn modal-btn-sm text-end">
                    <a href="javascript:void(0);" data-bs-dismiss="modal" class="btn btn-secondary">
                        Go Back
                    </a>
                    <a href="javascript:void(0);" data-bs-dismiss="modal" class="btn btn-primary">
                        Save & Continue
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /Edit Booking -->

<!-- Order Success Modal -->
<div class="modal new-modal order-success-modal fade" id="order_success" data-keyboard="false"
    data-backdrop="static">
<div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="order-success-info">
                    <span class="order-success-icon">
                        <img src="assets/img/icons/check-icon.svg" alt="Icon">
                    </span>
                    <h4>Successful</h4>
                    <p>You Amount “$1000” has been successfully added. Order id : <span>#50641</span></p>
                    <div class="modal-btn">
                        <a href="javascript:void(0);" class="btn btn-secondary">
                            Back to My Wallet <i class="feather icon-arrow-right"></i>
                        </a>
                    </div>
                   
                </div>
            </div>
        </div>
</div>
    <!-- /Order Success Modal -->


    
