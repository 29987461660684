import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { routes } from '../../routes/routes';
import { apiResultFormat, header } from '../model/model';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(private http: HttpClient) {}
  public getuserPayment() {
    return this.http.get<apiResultFormat>('assets/JSON/user-payment.json').pipe(
      map((res: apiResultFormat) => {
        return res;
      })
    );
  }
  public getuserReview() {
    return this.http.get<apiResultFormat>('assets/JSON/user-review.json').pipe(
      map((res: apiResultFormat) => {
        return res;
      })
    );
  }

  public getUserBookingCancelled() {
    return this.http
      .get<apiResultFormat>('assets/JSON/user-booking-cancelled.json')
      .pipe(
        map((res: apiResultFormat) => {
          return res;
        })
      );
  }
  public getuserWallet() {
    return this.http.get<apiResultFormat>('assets/JSON/user-wallet.json').pipe(
      map((res: apiResultFormat) => {
        return res;
      })
    );
  }

  public getUserBookingComplete() {
    return this.http
      .get<apiResultFormat>('assets/JSON/user-booking-complete.json')
      .pipe(
        map((res: apiResultFormat) => {
          return res;
        })
      );
  }
  public getUserBookingInprogress() {
    return this.http
      .get<apiResultFormat>('assets/JSON/user-booking-inprogress.json')
      .pipe(
        map((res: apiResultFormat) => {
          return res;
        })
      );
  }
  public getUserBookingUpcoming() {
    return this.http
      .get<apiResultFormat>('assets/JSON/user-booking-upcoming.json')
      .pipe(
        map((res: apiResultFormat) => {
          return res;
        })
      );
  }
  public getUserBookings() {
    return this.http
      .get<apiResultFormat>('assets/JSON/user-bookings.json')
      .pipe(
        map((res: apiResultFormat) => {
          return res;
        })
      );
  }

  public header: header[] = [
    {
      tittle: 'Pourquoi Talabcar ?',
      showAsTab: false,
      separateRoute: true,
      routes: routes.home,
      hasSubRoute: false,
      showSubRoute: false,

    },
    {
      tittle: 'Réservation',
      showAsTab: false,
      separateRoute: true,
      routes: routes.contactUs,
      hasSubRoute: false,
      showSubRoute: false,
    },
    {
      tittle: 'À propos',
      showAsTab: false,
      separateRoute: true,
      routes: routes.contactUs,
      hasSubRoute: false,
      showSubRoute: false,
    },
    {
      tittle: 'Contact',
      showAsTab: false,
      separateRoute: true,
      routes: routes.contactUs,
      hasSubRoute: false,
      showSubRoute: false,
    },
  ];
  public popularCars1 = [
    {
      carName: 'Toyota',
      img1: 'assets/img/cars/car-01.jpg',
      img2: 'assets/img/profiles/avatar-0.jpg',
      carModel: 'Toyota Camry SE 350',
      rating: '(5.0)',
      carDetail1: 'Auto',
      carDetail2: '10 KM',
      carDetail3: 'Petrol',
      carDetail4: 'Power',
      carDetail5: '2018',
      carDetail6: '5 Persons',
      country: 'Germany',
      price: '$400',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'KIA',
      img1: 'assets/img/cars/car-02.jpg',
      img2: 'assets/img/profiles/avatar-02.jpg',
      carModel: 'Kia Soul 2016',
      rating: '(5.0)',
      carDetail1: 'Auto',
      carDetail2: '22 KM',
      carDetail3: 'Petrol',
      carDetail4: 'Diesel',
      carDetail5: '2016',
      carDetail6: '5 Persons',
      country: 'Belgium',
      price: '$80',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Audi',
      img1: 'assets/img/cars/car-03.jpg',
      img2: 'assets/img/profiles/avatar-03.jpg',
      carModel: 'Audi A3 2019 new',
      rating: '(5.0)',
      carDetail1: 'Manual',
      carDetail2: '10 KM',
      carDetail3: 'Petrol',
      carDetail4: 'Power',
      carDetail5: '2019',
      carDetail6: '4 Persons',
      country: 'Newyork, USA',
      price: '$45',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Ferrai',
      img1: 'assets/img/cars/car-04.jpg',
      img2: 'assets/img/profiles/avatar-04.jpg',
      carModel: 'Ferrari 458 MM Speciale',
      rating: '(5.0)',
      carDetail1: 'Manual',
      carDetail2: '14 KM',
      carDetail3: 'Diesel',
      carDetail4: 'Basic',
      carDetail5: '2022',
      carDetail6: '5 Persons',
      country: 'Newyork, USA',
      price: '$160',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Chevrolet',
      img1: 'assets/img/cars/car-05.jpg',
      img2: 'assets/img/profiles/avatar-05.jpg',
      carModel: '2018 Chevrolet Camaro',
      rating: '(5.0)',
      carDetail1: 'Manual',
      carDetail2: '18 KM',
      carDetail3: 'Diesel',
      carDetail4: 'Power',
      carDetail5: '2018',
      carDetail6: '4 Persons',
      country: 'Germany',
      price: '$36',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Acura',
      img1: 'assets/img/cars/car-06.jpg',
      img2: 'assets/img/profiles/avatar-06.jpg',
      carModel: 'Acura Sport Version',
      rating: '(5.0)',
      carDetail1: 'Auto',
      carDetail2: '12 KM',
      carDetail3: 'Diesel',
      carDetail4: 'Power',
      carDetail5: '2013',
      carDetail6: '5 Persons',
      country: 'Newyork, USA',
      price: '$30',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Chevrolet',
      img1: 'assets/img/cars/car-07.jpg',
      img2: 'assets/img/profiles/avatar-07.jpg',
      carModel: 'Chevrolet Pick Truck 3.5L',
      rating: '(5.0)',
      carDetail1: 'Manual',
      carDetail2: '10 KM',
      carDetail3: 'Petrol',
      carDetail4: 'Power',
      carDetail5: '2012',
      carDetail6: '5 Persons',
      country: 'Spain',
      price: '$77',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Toyota',
      img1: 'assets/img/cars/car-08.jpg',
      img2: 'assets/img/profiles/avatar-08.jpg',
      carModel: 'Toyota Tacoma 4WD',
      rating: '(5.0)',
      carDetail1: 'Auto',
      carDetail2: '22 miles',
      carDetail3: 'Diesel',
      carDetail4: 'Power',
      carDetail5: '2019',
      carDetail6: '5 Persons',
      country: 'Dallas, USA',
      price: '$30',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Accura',
      img1: 'assets/img/cars/car-09.jpg',
      img2: 'assets/img/profiles/avatar-10.jpg',
      carModel: 'Acura RDX FWD',
      rating: '(5.0)',
      carDetail1: 'Auto',
      carDetail2: '42 miles',
      carDetail3: 'Petrol',
      carDetail4: 'Power',
      carDetail5: '2021',
      carDetail6: '5 Persons',
      country: 'Dallas, USA',
      price: '$80',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
  ];
  public popularCars2 = [
    {
      carName: 'Audi',
      img1: 'assets/img/cars/car-03.jpg',
      img2: 'assets/img/profiles/avatar-03.jpg',
      carModel: 'Audi A3 2019 new',
      rating: '(5.0)',
      carDetail1: 'Manual',
      carDetail2: '10 KM',
      carDetail3: 'Petrol',
      carDetail4: 'Power',
      carDetail5: '2019',
      carDetail6: '4 Persons',
      country: 'Newyork, USA',
      price: '$45',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Ferrai',
      img1: 'assets/img/cars/car-04.jpg',
      img2: 'assets/img/profiles/avatar-04.jpg',
      carModel: 'Ferrari 458 MM Speciale',
      rating: '(5.0)',
      carDetail1: 'Manual',
      carDetail2: '14 KM',
      carDetail3: 'Diesel',
      carDetail4: 'Basic',
      carDetail5: '2022',
      carDetail6: '5 Persons',
      country: 'Newyork, USA',
      price: '$160',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Chevrolet',
      img1: 'assets/img/cars/car-05.jpg',
      img2: 'assets/img/profiles/avatar-05.jpg',
      carModel: '2018 Chevrolet Camaro',
      rating: '(5.0)',
      carDetail1: 'Manual',
      carDetail2: '18 KM',
      carDetail3: 'Diesel',
      carDetail4: 'Power',
      carDetail5: '2018',
      carDetail6: '4 Persons',
      country: 'Germany',
      price: '$36',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Acura',
      img1: 'assets/img/cars/car-06.jpg',
      img2: 'assets/img/profiles/avatar-06.jpg',
      carModel: 'Acura Sport Version',
      rating: '(5.0)',
      carDetail1: 'Auto',
      carDetail2: '12 KM',
      carDetail3: 'Diesel',
      carDetail4: 'Power',
      carDetail5: '2013',
      carDetail6: '5 Persons',
      country: 'Newyork, USA',
      price: '$30',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Chevrolet',
      img1: 'assets/img/cars/car-07.jpg',
      img2: 'assets/img/profiles/avatar-07.jpg',
      carModel: 'Chevrolet Pick Truck 3.5L',
      rating: '(5.0)',
      carDetail1: 'Manual',
      carDetail2: '10 KM',
      carDetail3: 'Petrol',
      carDetail4: 'Power',
      carDetail5: '2012',
      carDetail6: '5 Persons',
      country: 'Spain',
      price: '$77',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Toyota',
      img1: 'assets/img/cars/car-08.jpg',
      img2: 'assets/img/profiles/avatar-08.jpg',
      carModel: 'Toyota Tacoma 4WD',
      rating: '(5.0)',
      carDetail1: 'Auto',
      carDetail2: '22 miles',
      carDetail3: 'Diesel',
      carDetail4: 'Power',
      carDetail5: '2019',
      carDetail6: '5 Persons',
      country: 'Dallas, USA',
      price: '$30',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Toyota',
      img1: 'assets/img/cars/car-01.jpg',
      img2: 'assets/img/profiles/avatar-0.jpg',
      carModel: 'Toyota Camry SE 350',
      rating: '(5.0)',
      carDetail1: 'Auto',
      carDetail2: '10 KM',
      carDetail3: 'Petrol',
      carDetail4: 'Power',
      carDetail5: '2018',
      carDetail6: '5 Persons',
      country: 'Germany',
      price: '$400',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'KIA',
      img1: 'assets/img/cars/car-02.jpg',
      img2: 'assets/img/profiles/avatar-02.jpg',
      carModel: 'Kia Soul 2016',
      rating: '(5.0)',
      carDetail1: 'Auto',
      carDetail2: '22 KM',
      carDetail3: 'Petrol',
      carDetail4: 'Diesel',
      carDetail5: '2016',
      carDetail6: '5 Persons',
      country: 'Belgium',
      price: '$80',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Accura',
      img1: 'assets/img/cars/car-09.jpg',
      img2: 'assets/img/profiles/avatar-10.jpg',
      carModel: 'Acura RDX FWD',
      rating: '(5.0)',
      carDetail1: 'Auto',
      carDetail2: '42 miles',
      carDetail3: 'Petrol',
      carDetail4: 'Power',
      carDetail5: '2021',
      carDetail6: '5 Persons',
      country: 'Dallas, USA',
      price: '$80',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
  ];
  public popularCars3 = [
    {
      carName: 'Toyota',
      img1: 'assets/img/cars/car-08.jpg',
      img2: 'assets/img/profiles/avatar-08.jpg',
      carModel: 'Toyota Tacoma 4WD',
      rating: '(5.0)',
      carDetail1: 'Auto',
      carDetail2: '22 miles',
      carDetail3: 'Diesel',
      carDetail4: 'Power',
      carDetail5: '2019',
      carDetail6: '5 Persons',
      country: 'Dallas, USA',
      price: '$30',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Toyota',
      img1: 'assets/img/cars/car-01.jpg',
      img2: 'assets/img/profiles/avatar-0.jpg',
      carModel: 'Toyota Camry SE 350',
      rating: '(5.0)',
      carDetail1: 'Auto',
      carDetail2: '10 KM',
      carDetail3: 'Petrol',
      carDetail4: 'Power',
      carDetail5: '2018',
      carDetail6: '5 Persons',
      country: 'Germany',
      price: '$400',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'KIA',
      img1: 'assets/img/cars/car-02.jpg',
      img2: 'assets/img/profiles/avatar-02.jpg',
      carModel: 'Kia Soul 2016',
      rating: '(5.0)',
      carDetail1: 'Auto',
      carDetail2: '22 KM',
      carDetail3: 'Petrol',
      carDetail4: 'Diesel',
      carDetail5: '2016',
      carDetail6: '5 Persons',
      country: 'Belgium',
      price: '$80',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Audi',
      img1: 'assets/img/cars/car-03.jpg',
      img2: 'assets/img/profiles/avatar-03.jpg',
      carModel: 'Audi A3 2019 new',
      rating: '(5.0)',
      carDetail1: 'Manual',
      carDetail2: '10 KM',
      carDetail3: 'Petrol',
      carDetail4: 'Power',
      carDetail5: '2019',
      carDetail6: '4 Persons',
      country: 'Newyork, USA',
      price: '$45',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Ferrai',
      img1: 'assets/img/cars/car-04.jpg',
      img2: 'assets/img/profiles/avatar-04.jpg',
      carModel: 'Ferrari 458 MM Speciale',
      rating: '(5.0)',
      carDetail1: 'Manual',
      carDetail2: '14 KM',
      carDetail3: 'Diesel',
      carDetail4: 'Basic',
      carDetail5: '2022',
      carDetail6: '5 Persons',
      country: 'Newyork, USA',
      price: '$160',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Chevrolet',
      img1: 'assets/img/cars/car-05.jpg',
      img2: 'assets/img/profiles/avatar-05.jpg',
      carModel: '2018 Chevrolet Camaro',
      rating: '(5.0)',
      carDetail1: 'Manual',
      carDetail2: '18 KM',
      carDetail3: 'Diesel',
      carDetail4: 'Power',
      carDetail5: '2018',
      carDetail6: '4 Persons',
      country: 'Germany',
      price: '$36',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Acura',
      img1: 'assets/img/cars/car-06.jpg',
      img2: 'assets/img/profiles/avatar-06.jpg',
      carModel: 'Acura Sport Version',
      rating: '(5.0)',
      carDetail1: 'Auto',
      carDetail2: '12 KM',
      carDetail3: 'Diesel',
      carDetail4: 'Power',
      carDetail5: '2013',
      carDetail6: '5 Persons',
      country: 'Newyork, USA',
      price: '$30',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Chevrolet',
      img1: 'assets/img/cars/car-07.jpg',
      img2: 'assets/img/profiles/avatar-07.jpg',
      carModel: 'Chevrolet Pick Truck 3.5L',
      rating: '(5.0)',
      carDetail1: 'Manual',
      carDetail2: '10 KM',
      carDetail3: 'Petrol',
      carDetail4: 'Power',
      carDetail5: '2012',
      carDetail6: '5 Persons',
      country: 'Spain',
      price: '$77',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Accura',
      img1: 'assets/img/cars/car-09.jpg',
      img2: 'assets/img/profiles/avatar-10.jpg',
      carModel: 'Acura RDX FWD',
      rating: '(5.0)',
      carDetail1: 'Auto',
      carDetail2: '42 miles',
      carDetail3: 'Petrol',
      carDetail4: 'Power',
      carDetail5: '2021',
      carDetail6: '5 Persons',
      country: 'Dallas, USA',
      price: '$80',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
  ];
  public popularCars4 = [
    {
      carName: 'Toyota',
      img1: 'assets/img/cars/car-01.jpg',
      img2: 'assets/img/profiles/avatar-0.jpg',
      carModel: 'Toyota Camry SE 350',
      rating: '(5.0)',
      carDetail1: 'Auto',
      carDetail2: '10 KM',
      carDetail3: 'Petrol',
      carDetail4: 'Power',
      carDetail5: '2018',
      carDetail6: '5 Persons',
      country: 'Germany',
      price: '$400',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'KIA',
      img1: 'assets/img/cars/car-02.jpg',
      img2: 'assets/img/profiles/avatar-02.jpg',
      carModel: 'Kia Soul 2016',
      rating: '(5.0)',
      carDetail1: 'Auto',
      carDetail2: '22 KM',
      carDetail3: 'Petrol',
      carDetail4: 'Diesel',
      carDetail5: '2016',
      carDetail6: '5 Persons',
      country: 'Belgium',
      price: '$80',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Audi',
      img1: 'assets/img/cars/car-03.jpg',
      img2: 'assets/img/profiles/avatar-03.jpg',
      carModel: 'Audi A3 2019 new',
      rating: '(5.0)',
      carDetail1: 'Manual',
      carDetail2: '10 KM',
      carDetail3: 'Petrol',
      carDetail4: 'Power',
      carDetail5: '2019',
      carDetail6: '4 Persons',
      country: 'Newyork, USA',
      price: '$45',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Ferrai',
      img1: 'assets/img/cars/car-04.jpg',
      img2: 'assets/img/profiles/avatar-04.jpg',
      carModel: 'Ferrari 458 MM Speciale',
      rating: '(5.0)',
      carDetail1: 'Manual',
      carDetail2: '14 KM',
      carDetail3: 'Diesel',
      carDetail4: 'Basic',
      carDetail5: '2022',
      carDetail6: '5 Persons',
      country: 'Newyork, USA',
      price: '$160',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Chevrolet',
      img1: 'assets/img/cars/car-05.jpg',
      img2: 'assets/img/profiles/avatar-05.jpg',
      carModel: '2018 Chevrolet Camaro',
      rating: '(5.0)',
      carDetail1: 'Manual',
      carDetail2: '18 KM',
      carDetail3: 'Diesel',
      carDetail4: 'Power',
      carDetail5: '2018',
      carDetail6: '4 Persons',
      country: 'Germany',
      price: '$36',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Acura',
      img1: 'assets/img/cars/car-06.jpg',
      img2: 'assets/img/profiles/avatar-06.jpg',
      carModel: 'Acura Sport Version',
      rating: '(5.0)',
      carDetail1: 'Auto',
      carDetail2: '12 KM',
      carDetail3: 'Diesel',
      carDetail4: 'Power',
      carDetail5: '2013',
      carDetail6: '5 Persons',
      country: 'Newyork, USA',
      price: '$30',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Chevrolet',
      img1: 'assets/img/cars/car-07.jpg',
      img2: 'assets/img/profiles/avatar-07.jpg',
      carModel: 'Chevrolet Pick Truck 3.5L',
      rating: '(5.0)',
      carDetail1: 'Manual',
      carDetail2: '10 KM',
      carDetail3: 'Petrol',
      carDetail4: 'Power',
      carDetail5: '2012',
      carDetail6: '5 Persons',
      country: 'Spain',
      price: '$77',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Toyota',
      img1: 'assets/img/cars/car-08.jpg',
      img2: 'assets/img/profiles/avatar-08.jpg',
      carModel: 'Toyota Tacoma 4WD',
      rating: '(5.0)',
      carDetail1: 'Auto',
      carDetail2: '22 miles',
      carDetail3: 'Diesel',
      carDetail4: 'Power',
      carDetail5: '2019',
      carDetail6: '5 Persons',
      country: 'Dallas, USA',
      price: '$30',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Accura',
      img1: 'assets/img/cars/car-09.jpg',
      img2: 'assets/img/profiles/avatar-10.jpg',
      carModel: 'Acura RDX FWD',
      rating: '(5.0)',
      carDetail1: 'Auto',
      carDetail2: '42 miles',
      carDetail3: 'Petrol',
      carDetail4: 'Power',
      carDetail5: '2021',
      carDetail6: '5 Persons',
      country: 'Dallas, USA',
      price: '$80',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
  ];
  public popularCars5 = [
    {
      carName: 'Toyota',
      img1: 'assets/img/cars/car-01.jpg',
      img2: 'assets/img/profiles/avatar-0.jpg',
      carModel: 'Toyota Camry SE 350',
      rating: '(5.0)',
      carDetail1: 'Auto',
      carDetail2: '10 KM',
      carDetail3: 'Petrol',
      carDetail4: 'Power',
      carDetail5: '2018',
      carDetail6: '5 Persons',
      country: 'Germany',
      price: '$400',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'KIA',
      img1: 'assets/img/cars/car-02.jpg',
      img2: 'assets/img/profiles/avatar-02.jpg',
      carModel: 'Kia Soul 2016',
      rating: '(5.0)',
      carDetail1: 'Auto',
      carDetail2: '22 KM',
      carDetail3: 'Petrol',
      carDetail4: 'Diesel',
      carDetail5: '2016',
      carDetail6: '5 Persons',
      country: 'Belgium',
      price: '$80',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Audi',
      img1: 'assets/img/cars/car-03.jpg',
      img2: 'assets/img/profiles/avatar-03.jpg',
      carModel: 'Audi A3 2019 new',
      rating: '(5.0)',
      carDetail1: 'Manual',
      carDetail2: '10 KM',
      carDetail3: 'Petrol',
      carDetail4: 'Power',
      carDetail5: '2019',
      carDetail6: '4 Persons',
      country: 'Newyork, USA',
      price: '$45',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Ferrai',
      img1: 'assets/img/cars/car-04.jpg',
      img2: 'assets/img/profiles/avatar-04.jpg',
      carModel: 'Ferrari 458 MM Speciale',
      rating: '(5.0)',
      carDetail1: 'Manual',
      carDetail2: '14 KM',
      carDetail3: 'Diesel',
      carDetail4: 'Basic',
      carDetail5: '2022',
      carDetail6: '5 Persons',
      country: 'Newyork, USA',
      price: '$160',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Chevrolet',
      img1: 'assets/img/cars/car-05.jpg',
      img2: 'assets/img/profiles/avatar-05.jpg',
      carModel: '2018 Chevrolet Camaro',
      rating: '(5.0)',
      carDetail1: 'Manual',
      carDetail2: '18 KM',
      carDetail3: 'Diesel',
      carDetail4: 'Power',
      carDetail5: '2018',
      carDetail6: '4 Persons',
      country: 'Germany',
      price: '$36',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Acura',
      img1: 'assets/img/cars/car-06.jpg',
      img2: 'assets/img/profiles/avatar-06.jpg',
      carModel: 'Acura Sport Version',
      rating: '(5.0)',
      carDetail1: 'Auto',
      carDetail2: '12 KM',
      carDetail3: 'Diesel',
      carDetail4: 'Power',
      carDetail5: '2013',
      carDetail6: '5 Persons',
      country: 'Newyork, USA',
      price: '$30',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Chevrolet',
      img1: 'assets/img/cars/car-07.jpg',
      img2: 'assets/img/profiles/avatar-07.jpg',
      carModel: 'Chevrolet Pick Truck 3.5L',
      rating: '(5.0)',
      carDetail1: 'Manual',
      carDetail2: '10 KM',
      carDetail3: 'Petrol',
      carDetail4: 'Power',
      carDetail5: '2012',
      carDetail6: '5 Persons',
      country: 'Spain',
      price: '$77',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Toyota',
      img1: 'assets/img/cars/car-08.jpg',
      img2: 'assets/img/profiles/avatar-08.jpg',
      carModel: 'Toyota Tacoma 4WD',
      rating: '(5.0)',
      carDetail1: 'Auto',
      carDetail2: '22 miles',
      carDetail3: 'Diesel',
      carDetail4: 'Power',
      carDetail5: '2019',
      carDetail6: '5 Persons',
      country: 'Dallas, USA',
      price: '$30',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Accura',
      img1: 'assets/img/cars/car-09.jpg',
      img2: 'assets/img/profiles/avatar-10.jpg',
      carModel: 'Acura RDX FWD',
      rating: '(5.0)',
      carDetail1: 'Auto',
      carDetail2: '42 miles',
      carDetail3: 'Petrol',
      carDetail4: 'Power',
      carDetail5: '2021',
      carDetail6: '5 Persons',
      country: 'Dallas, USA',
      price: '$80',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
  ];
  public popularCars6 = [
    {
      carName: 'Toyota',
      img1: 'assets/img/cars/car-08.jpg',
      img2: 'assets/img/profiles/avatar-08.jpg',
      carModel: 'Toyota Tacoma 4WD',
      rating: '(5.0)',
      carDetail1: 'Auto',
      carDetail2: '22 miles',
      carDetail3: 'Diesel',
      carDetail4: 'Power',
      carDetail5: '2019',
      carDetail6: '5 Persons',
      country: 'Dallas, USA',
      price: '$30',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Toyota',
      img1: 'assets/img/cars/car-01.jpg',
      img2: 'assets/img/profiles/avatar-0.jpg',
      carModel: 'Toyota Camry SE 350',
      rating: '(5.0)',
      carDetail1: 'Auto',
      carDetail2: '10 KM',
      carDetail3: 'Petrol',
      carDetail4: 'Power',
      carDetail5: '2018',
      carDetail6: '5 Persons',
      country: 'Germany',
      price: '$400',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'KIA',
      img1: 'assets/img/cars/car-02.jpg',
      img2: 'assets/img/profiles/avatar-02.jpg',
      carModel: 'Kia Soul 2016',
      rating: '(5.0)',
      carDetail1: 'Auto',
      carDetail2: '22 KM',
      carDetail3: 'Petrol',
      carDetail4: 'Diesel',
      carDetail5: '2016',
      carDetail6: '5 Persons',
      country: 'Belgium',
      price: '$80',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Audi',
      img1: 'assets/img/cars/car-03.jpg',
      img2: 'assets/img/profiles/avatar-03.jpg',
      carModel: 'Audi A3 2019 new',
      rating: '(5.0)',
      carDetail1: 'Manual',
      carDetail2: '10 KM',
      carDetail3: 'Petrol',
      carDetail4: 'Power',
      carDetail5: '2019',
      carDetail6: '4 Persons',
      country: 'Newyork, USA',
      price: '$45',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Ferrai',
      img1: 'assets/img/cars/car-04.jpg',
      img2: 'assets/img/profiles/avatar-04.jpg',
      carModel: 'Ferrari 458 MM Speciale',
      rating: '(5.0)',
      carDetail1: 'Manual',
      carDetail2: '14 KM',
      carDetail3: 'Diesel',
      carDetail4: 'Basic',
      carDetail5: '2022',
      carDetail6: '5 Persons',
      country: 'Newyork, USA',
      price: '$160',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Chevrolet',
      img1: 'assets/img/cars/car-05.jpg',
      img2: 'assets/img/profiles/avatar-05.jpg',
      carModel: '2018 Chevrolet Camaro',
      rating: '(5.0)',
      carDetail1: 'Manual',
      carDetail2: '18 KM',
      carDetail3: 'Diesel',
      carDetail4: 'Power',
      carDetail5: '2018',
      carDetail6: '4 Persons',
      country: 'Germany',
      price: '$36',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Acura',
      img1: 'assets/img/cars/car-06.jpg',
      img2: 'assets/img/profiles/avatar-06.jpg',
      carModel: 'Acura Sport Version',
      rating: '(5.0)',
      carDetail1: 'Auto',
      carDetail2: '12 KM',
      carDetail3: 'Diesel',
      carDetail4: 'Power',
      carDetail5: '2013',
      carDetail6: '5 Persons',
      country: 'Newyork, USA',
      price: '$30',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Chevrolet',
      img1: 'assets/img/cars/car-07.jpg',
      img2: 'assets/img/profiles/avatar-07.jpg',
      carModel: 'Chevrolet Pick Truck 3.5L',
      rating: '(5.0)',
      carDetail1: 'Manual',
      carDetail2: '10 KM',
      carDetail3: 'Petrol',
      carDetail4: 'Power',
      carDetail5: '2012',
      carDetail6: '5 Persons',
      country: 'Spain',
      price: '$77',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Accura',
      img1: 'assets/img/cars/car-09.jpg',
      img2: 'assets/img/profiles/avatar-10.jpg',
      carModel: 'Acura RDX FWD',
      rating: '(5.0)',
      carDetail1: 'Auto',
      carDetail2: '42 miles',
      carDetail3: 'Petrol',
      carDetail4: 'Power',
      carDetail5: '2021',
      carDetail6: '5 Persons',
      country: 'Dallas, USA',
      price: '$80',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
  ];
  public carousel = [
    {
      img: 'assets/img/profiles/avatar-02.jpg',
      Name: 'Rabien Ustoc',
      rating: '(5.0)',
      para: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed doeiusmod tempor incididunt ut labore et dolore magna aliqua. Utenim ad minim veniam, quis nostrud exercitation ullamco laborisnisi ut aliquip ex ea commodo consequat',
    },
    {
      img: 'assets/img/profiles/avatar-03.jpg',
      Name: 'Valerie L. Ellis',
      rating: '(5.0)',
      para: '  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed doeiusmod tempor incididunt ut labore et dolore magna aliqua. Utenim ad minim veniam, quis nostrud exercitation ullamco laborisnisi ut aliquip ex ea commodo consequat.',
    },
    {
      img: 'assets/img/profiles/avatar-04.jpg',
      Name: 'Laverne Marier',
      rating: '(5.0)',
      para: '  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed doeiusmod tempor incididunt ut labore et dolore magna aliqua. Utenim ad minim veniam, quis nostrud exercitation ullamco laborisnisi ut aliquip ex ea commodo consequat.',
    },
    {
      img: 'assets/img/profiles/avatar-06.jpg',
      Name: 'Sydney Salmons',
      rating: '(5.0)',
      para: '  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed doeiusmod tempor incididunt ut labore et dolore magna aliqua. Utenim ad minim veniam, quis nostrud exercitation ullamco laborisnisi ut aliquip ex ea commodo consequat.',
    },
    {
      img: 'assets/img/profiles/avatar-07.jpg',
      Name: 'Lucas Moquin',
      rating: '(5.0)',
      para: '  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed doeiusmod tempor incididunt ut labore et dolore magna aliqua. Utenim ad minim veniam, quis nostrud exercitation ullamco laborisnisi ut aliquip ex ea commodo consequat.',
    },
  ];
  public recommendedCar = [
    {
      img: 'assets/img/cars/car-01.jpg',
      text: '$400/day',
      carModel: 'Toyota Camry SE 350',
      heading: 'Listed By',
      name: 'Venis Darren',
      carDetail1: 'Auto',
      carDetail2: '10 KM',
      carDetail3: 'Petrol',
      carDetail4: 'Power',
      carDetail5: '2018',
      carDetail6: '5 Persons',
      title: 'Rent Now',
    },
    {
      img: 'assets/img/cars/car-02.jpg',
      text: '$400/day',
      carModel: 'Toyota Camry SE 350',
      heading: 'Listed By',
      name: 'Venis Darren',
      carDetail1: 'Auto',
      carDetail2: '10 KM',
      carDetail3: 'Petrol',
      carDetail4: 'Power',
      carDetail5: '2018',
      carDetail6: '5 Persons',
      title: 'Rent Now',
    },
    {
      img: 'assets/img/cars/car-03.jpg',
      text: '$400/day',
      carModel: 'Toyota Camry SE 350',
      heading: 'Listed By',
      name: 'Venis Darren',
      carDetail1: 'Auto',
      carDetail2: '10 KM',
      carDetail3: 'Petrol',
      carDetail4: 'Power',
      carDetail5: '2018',
      carDetail6: '5 Persons',
      title: 'Rent Now',
    },
  ];
  public testimonial = [
    {
      img: 'assets/img/profiles/avatar-02.jpg',
      Name: 'Rabien Ustoc',
      rating: '(5.0)',
      para: ' Renting a car from Dreams rent made my vacation so much smoother! The process was quick and easy, the car was clean and well-maintained, and the staff were friendly and helpful.',
    },
    {
      img: 'assets/img/profiles/avatar-03.jpg',
      Name: 'Valerie L. Ellis',
      rating: '(5.0)',
      para: 'As a frequent business traveller, I rely on Dreams rent for all my needs. Their wide selection of vehicles, convenient locations, and competitive prices make them my go-to choice every time. Plus, their customer service is top-notch!',
    },
    {
      img: 'assets/img/profiles/avatar-04.jpg',
      Name: 'Laverne Marier',
      rating: '(5.0)',
      para: 'Renting a car from Dreams rent made our family vacation unforgettable and top-notch customer service. The spacious SUV we rented comfortably fit our family and all our luggage, and it was a smooth ride throughout our trip.',
    },
    {
      img: 'assets/img/profiles/avatar-06.jpg',
      Name: 'Sydney Salmons',
      rating: '(5.0)',
      para: 'As a frequent business traveller, I rely on Dreams rent for all my needs. Their wide selection of vehicles, convenient locations, and competitive prices make them my go-to choice every time. Plus, their customer service is top-notch!',
    },
    {
      img: 'assets/img/profiles/avatar-07.jpg',
      Name: 'Lucas Moquin',
      rating: '(5.0)',
      para: 'Renting a car from Dreams rent made our family vacation unforgettable and top-notch customer service. The spacious SUV we rented comfortably fit our family and all our luggage, and it was a smooth ride throughout our trip.',
    },
  ];
  public carTypes = [
    {
      img: 'assets/img/cars/mp-vehicle-01.svg',
      heading: 'Crossover',
      cars: '35 Cars',
    },
    {
      img: 'assets/img/cars/mp-vehicle-02.svg',
      heading: 'Sports Coupe',
      cars: '45 Cars',
    },
    {
      img: 'assets/img/cars/mp-vehicle-03.svg',
      heading: 'Sedan',
      cars: '15 Cars',
    },
    {
      img: 'assets/img/cars/mp-vehicle-04.svg',
      heading: 'Pickup',
      cars: '17 Cars',
    },
    {
      img: 'assets/img/cars/mp-vehicle-05.svg',
      heading: 'Family MPV',
      cars: '24 Cars',
    },
  ];
  public listingGrid = [
    {
      carName: 'Toyota',
      img1: 'assets/img/cars/car-01.jpg',
      img2: 'assets/img/profiles/avatar-0.jpg',
      carModel: 'Toyota Camry SE 350',
      rating: '(5.0)',
      carDetail1: 'Auto',
      carDetail2: '10 KM',
      carDetail3: 'Petrol',
      carDetail4: 'Power',
      carDetail5: '2018',
      carDetail6: '5 Persons',
      country: 'Germany',
      price: '$400',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'KIA',
      img1: 'assets/img/cars/car-02.jpg',
      img2: 'assets/img/profiles/avatar-02.jpg',
      carModel: 'Kia Soul 2016',
      rating: '(5.0)',
      carDetail1: 'Auto',
      carDetail2: '22 KM',
      carDetail3: 'Petrol',
      carDetail4: 'Diesel',
      carDetail5: '2016',
      carDetail6: '5 Persons',
      country: 'Belgium',
      price: '$80',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Audi',
      img1: 'assets/img/cars/car-03.jpg',
      img2: 'assets/img/profiles/avatar-03.jpg',
      carModel: 'Audi A3 2019 new',
      rating: '(5.0)',
      carDetail1: 'Manual',
      carDetail2: '10 KM',
      carDetail3: 'Petrol',
      carDetail4: 'Power',
      carDetail5: '2019',
      carDetail6: '4 Persons',
      country: 'Newyork, USA',
      price: '$45',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Ferrai',
      img1: 'assets/img/cars/car-04.jpg',
      img2: 'assets/img/profiles/avatar-04.jpg',
      carModel: 'Ferrari 458 MM Speciale',
      rating: '(5.0)',
      carDetail1: 'Manual',
      carDetail2: '14 KM',
      carDetail3: 'Diesel',
      carDetail4: 'Basic',
      carDetail5: '2022',
      carDetail6: '5 Persons',
      country: 'Newyork, USA',
      price: '$160',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Chevrolet',
      img1: 'assets/img/cars/car-05.jpg',
      img2: 'assets/img/profiles/avatar-05.jpg',
      carModel: '2018 Chevrolet Camaro',
      rating: '(5.0)',
      carDetail1: 'Manual',
      carDetail2: '18 KM',
      carDetail3: 'Diesel',
      carDetail4: 'Power',
      carDetail5: '2018',
      carDetail6: '4 Persons',
      country: 'Germany',
      price: '$36',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Acura',
      img1: 'assets/img/cars/car-06.jpg',
      img2: 'assets/img/profiles/avatar-06.jpg',
      carModel: 'Acura Sport Version',
      rating: '(5.0)',
      carDetail1: 'Auto',
      carDetail2: '12 KM',
      carDetail3: 'Diesel',
      carDetail4: 'Power',
      carDetail5: '2013',
      carDetail6: '5 Persons',
      country: 'Newyork, USA',
      price: '$30',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Chevrolet',
      img1: 'assets/img/cars/car-07.jpg',
      img2: 'assets/img/profiles/avatar-07.jpg',
      carModel: 'Chevrolet Pick Truck 3.5L',
      rating: '(5.0)',
      carDetail1: 'Manual',
      carDetail2: '10 KM',
      carDetail3: 'Petrol',
      carDetail4: 'Power',
      carDetail5: '2012',
      carDetail6: '5 Persons',
      country: 'Spain',
      price: '$77',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Toyota',
      img1: 'assets/img/cars/car-08.jpg',
      img2: 'assets/img/profiles/avatar-08.jpg',
      carModel: 'Toyota Tacoma 4WD',
      rating: '(5.0)',
      carDetail1: 'Auto',
      carDetail2: '22 miles',
      carDetail3: 'Diesel',
      carDetail4: 'Power',
      carDetail5: '2019',
      carDetail6: '5 Persons',
      country: 'Dallas, USA',
      price: '$30',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Accura',
      img1: 'assets/img/cars/car-09.jpg',
      img2: 'assets/img/profiles/avatar-10.jpg',
      carModel: 'Acura RDX FWD',
      rating: '(5.0)',
      carDetail1: 'Auto',
      carDetail2: '42 miles',
      carDetail3: 'Petrol',
      carDetail4: 'Power',
      carDetail5: '2021',
      carDetail6: '5 Persons',
      country: 'Dallas, USA',
      price: '$80',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'KIA',
      img1: 'assets/img/cars/car-02.jpg',
      img2: 'assets/img/profiles/avatar-02.jpg',
      carModel: 'Kia Soul 2016',
      rating: '(5.0)',
      carDetail1: 'Auto',
      carDetail2: '22 KM',
      carDetail3: 'Petrol',
      carDetail4: 'Diesel',
      carDetail5: '2016',
      carDetail6: '5 Persons',
      country: 'Belgium',
      price: '$80',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
  ];
  public listingDetails = [
    {
      img: 'assets/img/cars/slider-01.jpg',
    },
    {
      img: 'assets/img/cars/slider-02.jpg',
    },
    {
      img: 'assets/img/cars/slider-03.jpg',
    },
    {
      img: 'assets/img/cars/slider-04.jpg',
    },
    {
      img: 'assets/img/cars/slider-05.jpg',
    },
  ];
  public thumbnails1 = [
    {
      img: 'assets/img/profiles/avatar-11.jpg',
    },
    {
      img: 'assets/img/profiles/avatar-02.jpg',
    },
    {
      img: 'assets/img/profiles/avatar-03.jpg',
    },
    {
      img: 'assets/img/profiles/avatar-04.jpg',
    },
  ];
  public testimonials = [
    {
      name: 'Marian Hendriques',
      location: 'Dubai, Emirates',
     
      title: '“ From a Satisfied Business Traveler “',
      content: 'As a frequent business traveler, I rely on Dreams Rent for all my transportation needs. Their extensive fleet of vehicles, convenient locations, and competitive pricing make them my go-to choice every time. Plus, their friendly staff always go the extra mile to ensure a seamless rental experience.'
    },
    {
      name: 'Lyon Avenue',
      location: 'Derby, UK',
     
      title: '“ David\'s Urban Exploration “',
      content: 'As a frequent traveler, finding reliable bike rental services is crucial for me. I stumbled upon this website during my recent trip, and I\'m glad I did. The process of booking was seamless, and the prices were reasonable. The best part was the quality of the bikes; they were well-maintained and comfortable to ride.'
    },
    {
      name: 'Westfall Avenue',
      location: 'New York, USA',
      
      title: '“ Sarah\'s Adventure “',
      content: 'Absolutely loved my experience with Dreams Rent! Booking was a breeze; their website is user-friendly and intuitive. The bike I rented was in excellent condition, which made exploring the city a joy. What stood out the most was the exceptional customer service.'
    },
    {
      name: 'Saint Clair Street',
      location: 'Norwich, UK',
     
      title: '“ Edward\'s Scenic Ride “',
      content: 'From start to finish, renting a bike through this website was an absolute pleasure. The website interface was easy to navigate, and I could quickly find the perfect bike for my needs. When I arrived to pick up the bike, I was impressed by its excellent condition. It was evident that they take pride in maintaining their fleet.'
    }
    
  ];
  
  public thumbnails = [
    {
      img: 'assets/img/cars/slider-thum-01.jpg',
    },
    {
      img: 'assets/img/cars/slider-thum-02.jpg',
    },
    {
      img: 'assets/img/cars/slider-thum-03.jpg',
    },
    {
      img: 'assets/img/cars/slider-thum-04.jpg',
    },
    {
      img: 'assets/img/cars/slider-thum-05.jpg',
    },
  ];
  public gallery = [
    {
      img: 'assets/img/gallery/gallery-01.jpg',
      thumbImg: 'assets/img/gallery/gallery-thum-01.jpg',
    },
    {
      img: 'assets/img/gallery/gallery-02.jpg',
      thumbImg: 'assets/img/gallery/gallery-thum-02.jpg',
    },
    {
      img: 'assets/img/gallery/gallery-03.jpg',
      thumbImg: 'assets/img/gallery/gallery-thum-03.jpg',
    },
    {
      img: 'assets/img/gallery/gallery-04.jpg',
      thumbImg: 'assets/img/gallery/gallery-thum-04.jpg',
    },
    {
      img: 'assets/img/gallery/gallery-05.jpg',
      thumbImg: 'assets/img/gallery/gallery-thum-05.jpg',
    },
    {
      img: 'assets/img/gallery/gallery-06.jpg',
      thumbImg: 'assets/img/gallery/gallery-thum-06.jpg',
    },
    {
      img: 'assets/img/gallery/gallery-07.jpg',
      thumbImg: 'assets/img/gallery/gallery-thum-07.jpg',
    },
    {
      img: 'assets/img/gallery/gallery-08.jpg',
      thumbImg: 'assets/img/gallery/gallery-thum-08.jpg',
    },
    {
      img: 'assets/img/gallery/gallery-09.jpg',
      thumbImg: 'assets/img/gallery/gallery-thum-09.jpg',
    },
    {
      img: 'assets/img/gallery/gallery-10.jpg',
      thumbImg: 'assets/img/gallery/gallery-thum-10.jpg',
    },
    {
      img: 'assets/img/gallery/gallery-11.jpg',
      thumbImg: 'assets/img/gallery/gallery-thum-11.jpg',
    },
    {
      img: 'assets/img/gallery/gallery-12.jpg',
      thumbImg: 'assets/img/gallery/gallery-thum-12.jpg',
    },
  ];
  public interestedCars = [
    {
      carName: 'KIA',
      img1: 'assets/img/cars/car-02.jpg',
      img2: 'assets/img/profiles/avatar-02.jpg',
      carModel: 'Kia Soul 2016',
      rating: '(5.0)',
      carDetail1: 'Auto',
      carDetail2: '22 KM',
      carDetail3: 'Petrol',
      carDetail4: 'Diesel',
      carDetail5: '2016',
      carDetail6: '5 Persons',
      country: 'Belgium',
      price: '$80',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Chevrolet',
      img1: 'assets/img/cars/car-05.jpg',
      img2: 'assets/img/profiles/avatar-05.jpg',
      carModel: '2018 Chevrolet Camaro',
      rating: '(5.0)',
      carDetail1: 'Manual',
      carDetail2: '18 KM',
      carDetail3: 'Diesel',
      carDetail4: 'Power',
      carDetail5: '2018',
      carDetail6: '4 Persons',
      country: 'Germany',
      price: '$36',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Audi',
      img1: 'assets/img/cars/car-03.jpg',
      img2: 'assets/img/profiles/avatar-03.jpg',
      carModel: 'Audi A3 2019 new',
      rating: '(5.0)',
      carDetail1: 'Manual',
      carDetail2: '10 KM',
      carDetail3: 'Petrol',
      carDetail4: 'Power',
      carDetail5: '2019',
      carDetail6: '4 Persons',
      country: 'Newyork, USA',
      price: '$45',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Ferrai',
      img1: 'assets/img/cars/car-04.jpg',
      img2: 'assets/img/profiles/avatar-04.jpg',
      carModel: 'Ferrari 458 MM Speciale',
      rating: '(5.0)',
      carDetail1: 'Manual',
      carDetail2: '14 KM',
      carDetail3: 'Diesel',
      carDetail4: 'Basic',
      carDetail5: '2022',
      carDetail6: '5 Persons',
      country: 'Newyork, USA',
      price: '$160',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Chevrolet',
      img1: 'assets/img/cars/car-05.jpg',
      img2: 'assets/img/profiles/avatar-05.jpg',
      carModel: '2018 Chevrolet Camaro',
      rating: '(5.0)',
      carDetail1: 'Manual',
      carDetail2: '18 KM',
      carDetail3: 'Diesel',
      carDetail4: 'Power',
      carDetail5: '2018',
      carDetail6: '4 Persons',
      country: 'Germany',
      price: '$36',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
    {
      carName: 'Acura',
      img1: 'assets/img/cars/car-06.jpg',
      img2: 'assets/img/profiles/avatar-06.jpg',
      carModel: 'Acura Sport Version',
      rating: '(5.0)',
      carDetail1: 'Auto',
      carDetail2: '12 KM',
      carDetail3: 'Diesel',
      carDetail4: 'Power',
      carDetail5: '2013',
      carDetail6: '5 Persons',
      country: 'Newyork, USA',
      price: '$30',
      heading: '/ Day',
      text: 'Rent Now',
      like: false,
    },
  ];
}
