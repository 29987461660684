import * as i0 from '@angular/core';
import { EventEmitter, PLATFORM_ID, Directive, Inject, Input, Output, HostListener, NgModule } from '@angular/core';
import { CountUp } from 'countup.js';
import { isPlatformBrowser } from '@angular/common';
class CountUpDirective {
  constructor(el, zone, platformId) {
    this.el = el;
    this.zone = zone;
    this.platformId = platformId;
    this.options = {};
    this.reanimateOnClick = true;
    // eslint-disable-next-line @angular-eslint/no-output-native
    this.complete = new EventEmitter();
  }
  // Re-animate if preference is set.
  onClick() {
    if (this.reanimateOnClick) {
      this.animate();
    }
  }
  ngOnChanges(changes) {
    // don't animate server-side (universal)
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    const {
      options,
      endVal
    } = changes;
    if (endVal?.currentValue !== undefined) {
      if (this.countUp !== undefined) {
        this.zone.runOutsideAngular(() => {
          this.countUp.update(this.endVal);
        });
      } else {
        this.initAndRun();
      }
    } else if (options?.currentValue !== undefined) {
      this.initAndRun();
    }
  }
  animate() {
    this.zone.runOutsideAngular(() => {
      this.countUp.reset();
      this.countUp.start(() => {
        this.zone.run(() => {
          this.complete.emit();
        });
      });
    });
  }
  initAndRun() {
    this.zone.runOutsideAngular(() => {
      this.countUp = new CountUp(this.el.nativeElement, this.endVal, this.options);
      if (!this.options.enableScrollSpy) {
        this.animate();
      }
    });
  }
}
CountUpDirective.ɵfac = function CountUpDirective_Factory(t) {
  return new (t || CountUpDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(PLATFORM_ID));
};
CountUpDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: CountUpDirective,
  selectors: [["", "countUp", ""]],
  hostBindings: function CountUpDirective_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("click", function CountUpDirective_click_HostBindingHandler() {
        return ctx.onClick();
      });
    }
  },
  inputs: {
    endVal: [i0.ɵɵInputFlags.None, "countUp", "endVal"],
    options: "options",
    reanimateOnClick: "reanimateOnClick"
  },
  outputs: {
    complete: "complete"
  },
  features: [i0.ɵɵNgOnChangesFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CountUpDirective, [{
    type: Directive,
    args: [{
      selector: '[countUp]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }, {
      type: Object,
      decorators: [{
        type: Inject,
        args: [PLATFORM_ID]
      }]
    }];
  }, {
    endVal: [{
      type: Input,
      args: ['countUp']
    }],
    options: [{
      type: Input
    }],
    reanimateOnClick: [{
      type: Input
    }],
    complete: [{
      type: Output
    }],
    onClick: [{
      type: HostListener,
      args: ['click']
    }]
  });
})();
class CountUpModule {}
CountUpModule.ɵfac = function CountUpModule_Factory(t) {
  return new (t || CountUpModule)();
};
CountUpModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: CountUpModule
});
CountUpModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CountUpModule, [{
    type: NgModule,
    args: [{
      imports: [],
      declarations: [CountUpDirective],
      exports: [CountUpDirective]
    }]
  }], null, null);
})();

/*
 * Public API Surface of count-up
 */

/**
 * Generated bundle index. Do not edit.
 */

export { CountUpDirective, CountUpModule };
